import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/userAction';
import { checkFields } from '../../config/utils';
import { errorMessagesReset } from '../../config/messages';

class ResetPassword extends Component {
    state = {
        formErrors: {},
        reset_token: undefined,
        success_reset: false,
        loading_reset: false,
        password: undefined,
        passwordConfirmation: undefined,
        check_confirmation: true
    }

    componentDidMount = () => {
        this.setState({
            reset_token: this.props.match.params.token
        })
    }

    handleChangeInput = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    checkPasswordConfirmation = () => {
        this.setState({
            check_confirmation: true,
            formErrors: {}
        })
        if (this.state.password && this.state.passwordConfirmation && this.state.password !== this.state.passwordConfirmation) {
            this.setState({
                check_confirmation: false
            })
        }
    }

    handleResetPassword = (e) => {
        e.preventDefault();
        this.setState({
            loading_reset: true,
            formErrors: {},
            reset_success: undefined,
            reset_error: undefined
        })
        checkFields(errorMessagesReset, this.state).then(() => {
            if (this.state.password === this.state.passwordConfirmation) {
                this.setState({
                    check_confirmation: true,
                })
                this.props.resetPassword(this.state.reset_token, this.state.password).then(res => {
                    this.setState({
                        success_reset: true,
                        loading_reset: false,
                        formErrors: {},
                        reset_success: true
                    })
                }).catch(err => {
                    this.setState(state => ({
                        success_reset: false,
                        loading_reset: false,
                        formErrors: {
                            ...state.formErrors,
                            reset: err.message.response.data.message
                        },
                        reset_error: true
                    }))
                })
            } else {
                this.setState({
                    loading_reset: false
                })
            }
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    reset: errors
                },
                loading_reset: false,
                success_reset: false
            }))
        })
    }

    render() {
        const { Layout, title } = this.props;

        return (
            <Layout
                title={title}
                handleChangeInput={this.handleChangeInput.bind(this)}
                state={this.state}
                handleResetPassword={this.handleResetPassword.bind(this)}
                checkPasswordConfirmation={this.checkPasswordConfirmation.bind(this)}
                loading_reset={this.state.loading_reset}
                reset_success={this.state.reset_success}
                reset_error={this.state.reset_error}
            />
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);