import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import '../../styles/Pages/signup.scss';
import Loading from '../UI/Loading';
import LocationSearchInput from '../../containers/Utils/LocationSearchInput';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { useTranslation } from 'react-i18next';

const SignUpComponent = ({
    isLoadingRegister,
    handleUpdateSignUpInput,
    handleCreateUser,
    checkPasswordConfirmation,
    created,
    formErrors,
    title,
    state,
    checkPasswordFormat,
    wrong_format,
    check_confirmation,
    handlePhoneInput }) => {

    document.title = title;

    const { t, i18n } = useTranslation();

    return (
        <div id="signUpContainer">
            <div id="formWrapper">
                <div className="signUpTitle" style={{ textAlign: "center" }}>
                    <img className="logo" src={require("../../assets/logo/kwanuko_dragon.png")} alt="dragon logo" />
                    <h4 className="mt-3">{t('register.create_account')}</h4>
                    <hr />
                </div>

                {created ?
                    <>
                        <h5 className="text-center">{t('register.confirm_email')}</h5>
                    </>
                    :
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="firstname">{t('register.first_name')}</Label>
                                    <Input
                                        className={`${state && state.firstname ? 'fullinput' : ''}`}
                                        type="text" name="firstname" onChange={e => handleUpdateSignUpInput(e)} placeholder={t("register_placeholder.first_name")}></Input>
                                </Col>
                                <Col>
                                    <Label className="labelWithText" htmlFor="lastname">{t('register.last_name')}</Label>
                                    <Input
                                        type="text"
                                        name="lastname"
                                        onChange={e => handleUpdateSignUpInput(e)}
                                        placeholder={t("register_placeholder.last_name")}
                                        className={`${state && state.lastname ? 'fullinput' : ''}`}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="email">{t('register.email')}</Label>
                                    <Input
                                        className={`${state && state.email ? 'fullinput' : ''}`}
                                        type="email" name="email" onChange={e => handleUpdateSignUpInput(e)} placeholder={t("register_placeholder.email")}></Input>

                                </Col>
                                <Col>
                                    <Label className="labelWithText" htmlFor="phone">{t('register.phone')}</Label>
                                    <PhoneInput
                                        country={'ca'}
                                        value={state.phone}
                                        onChange={phone => handlePhoneInput(phone)}
                                        inputClass={`${state && state.phone ? 'fullinput' : ''}`}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="address">{t('register.address')}</Label>
                                    <LocationSearchInput
                                        displayLabel={false}
                                        placeholder={t("register_placeholder.address")}
                                        name="address"
                                        value=""
                                        className={`${state && state.address ? 'fullinput' : ''}`}
                                        onSelect={e => {
                                            handleUpdateSignUpInput({ target: { name: "address", value: e.formatted_address } });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="intro">{t('register.introduction')}</Label>
                                    <Input
                                        className={`${state && state.intro ? 'fullinput' : ''}`}
                                        type="textarea" name="intro" onChange={e => handleUpdateSignUpInput(e)} placeholder={t("register_placeholder.introduction")}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="area">{t('register.area_title')}</Label>
                                    <Input
                                        className={`${state && state.area ? 'fullinput' : ''}`}
                                        type="text" name="area" onChange={e => handleUpdateSignUpInput(e)} placeholder={t("register_placeholder.area_description")}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="adRate">{t('register.offer_rate')}</Label>
                                    <Input type="number" name="adRate" onChange={e => handleUpdateSignUpInput(e)} placeholder={t('register_placeholder.offer_rate')}
                                        className={`${state && state.adRate ? 'fullinput' : ''}`}
                                    ></Input>
                                    <FormText color="muted">
                                        {t('register.offer_rate_description')}
                                    </FormText>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="subscriptionAsked">{t('register.offer_subscription')}</Label>
                                    <FormText color="muted">
                                        {t('register.offer_subscription_description')}
                                    </FormText>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="subscriptionAsked" value="monthly" onChange={e => handleUpdateSignUpInput(e)} />{' '}
                                            {t("global.monthly")}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="subscriptionAsked" value="annual" onChange={e => handleUpdateSignUpInput(e)} />{' '}
                                            {t("global.annual")}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="password">{t('register.password')}</Label>
                                    <Input
                                        className={`${state && state.password ? 'fullinput' : ''}`}
                                        type="password"
                                        name="password"
                                        onBlur={checkPasswordFormat}
                                        onChange={e => handleUpdateSignUpInput(e)}
                                        placeholder={t("register_placeholder.password")}></Input>
                                    {
                                        wrong_format ?
                                            <span className="errormessagesmall" key={`error${t}`}>{t('register_logic.password_format')}</span>
                                            :
                                            undefined
                                    }
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="confirmPassword">{t('register.password_confirmation')}</Label>
                                    <Input
                                        className={`${state && state.confirmPassword ? 'fullinput' : ''}`}
                                        type="password"
                                        name="confirmPassword"
                                        onBlur={checkPasswordConfirmation}
                                        onChange={e => handleUpdateSignUpInput(e)}
                                        placeholder={t("register_placeholder.password_confirmation")}></Input>
                                    {
                                        check_confirmation === false ?
                                            <span className="errormessagesmall" key={`error${t}`}>{t('register_logic.password_match')}</span>
                                            :
                                            undefined
                                    }
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="sponsorCode">{t('register.referral')}</Label>
                                    <Input
                                        defaultValue={state.sponsorCode}
                                        className={`${state && state.sponsorCode ? 'fullinput' : ''}`}
                                        type="text"
                                        name="sponsorCode"
                                        onChange={e => handleUpdateSignUpInput(e)}
                                        placeholder={t("register_placeholder.referral")}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        {formErrors && formErrors.register ? Object.keys(formErrors.register).map(t => formErrors.register[t] ? <span className="errormessagesmall" key={`error${t}`}>{i18n.t(formErrors.register[t])}</span> : undefined) : undefined}

                        {
                            state && state.errors && state.errors.existing_user ?
                                <span className="errormessagesmall">{state.errors.existing_user}</span>
                                :
                                undefined
                        }

                        <div className="registerButtonWrapper" >
                            <Button
                                onClick={e => handleCreateUser(e)}
                                // disabled={!user || !user.lastname || !user.firstname || !user.email || !user.email.match(/^\S+@\S+\.\S+$/) || !user.phone || !user.password || !user.confirmPassword || user.password !== user.confirmPassword}
                                className={`${isLoadingRegister ? 'loading' : ''} text-uppercase mt-4 kwanukobutton`}
                            >
                                {
                                    isLoadingRegister ?
                                        <Loading button={true} />
                                        :
                                        t('register.submit')
                                }
                            </Button>
                        </div>
                    </Form>
                }
            </div>
        </div>
    );
}

export default SignUpComponent;