import React, { Component } from 'react';
import { connect } from 'react-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { createOffer } from "../../actions/offerAction";
import { getAllCategories } from "../../actions/categoryAction"
import { isConnected, uploadFile, uploadMultipleFiles, clearImages, deleteImage } from "../../actions/userAction"
import { getUserOffers } from '../../actions/offerAction';

import { checkAdFields } from '../../config/utils';
import { errorMessagesAd } from '../../config/messages';

import i18n from '../../translations/i18n';
class OfferForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offer: {
                bannerImage: undefined,
                otherImages: [],
                distance: 20,
                displayAddress: false,
                language: i18n.language === 'fr' ? 'Français' : 'English'
            },
            isLoading: false,
            isCreated: false,
            formErrors: {},
        };
    }

    componentDidMount = () => {
        this.props.isConnected().then(() => {
            this.props.getUserOffers(this.props.user).then((res) => {
                if (res && res.payload && res.payload.length >= 1) {
                    this.props.history.push("/login");
                } else {
                    this.props.getAllCategories()
                        .then(() => {
                            this.props.clearImages();
                        })
                        .catch(() => { });
                }
            })
                .catch(() => {
                    this.props.history.push("/login");
                })
        }).catch(() => {
            this.props.history.push("/login");
        })
    }

    componentDidUpdate(previousProps) {
        if (previousProps.otherImages !== this.props.otherImages) {
            this.setState({
                ...this.state,
                isLoading: true,
                offer: {
                    ...this.state.offer,
                    otherImages: this.props.otherImages
                }
            }, () => {
                this.setState({ isLoading: false });
            })
        }
    }


    handleUpdateAdInput = e => {
        if (e.target.name === "keywords") {
            this.setState({ offer: { ...this.state.offer, [e.target.name]: e.target.value.toLowerCase() } });
        } else
            if (e.target.name === "address") {
                geocodeByAddress(e.target.value)
                    .then(results => {
                        getLatLng(results[0]).then(latLng => {
                            this.setState({
                                offer: {
                                    ...this.state.offer,
                                    latitude: latLng.lat,
                                    longitude: latLng.lng,
                                    [e.target.name]: e.target.value
                                }
                            });
                        })
                            .catch(err => { })
                    })
                    .catch(error => { });
            } else
                if (e.target.name === "displayAddress") {
                    this.setState({ offer: { ...this.state.offer, [e.target.name]: e.target.checked } });
                }
                else this.setState({ offer: { ...this.state.offer, [e.target.name]: e.target.value } });
    }

    handleEditorChange = (e, name) => {
        let text = e.target.getContent();

        text = text.replace(/<strong/g, "<strong style='font-family:MontBold;'");
        this.setState({ offer: { ...this.state.offer, [name]: text } });
    }

    uploadBannerImage = image => {
        let aFile = image[0];
        this.setState({ ...this.state, isLoadingUpload: true });

        this.props.uploadFile(aFile).then(() => {
            this.setState({
                ...this.state,
                isLoading: false,
                isLoadingUpload: false,
                offer: {
                    ...this.state.offer,
                    bannerImage: this.props.bannerImage
                }
            });
        }
        )
    }

    uploadOtherImages = (images) => {
        this.setState({ ...this.state, isLoadingUploadOther: true });
        if (this.state.offer.otherImages.length === 2) {
            this.props.deleteImage(this.state.offer.otherImages[1]);

        }

        this.props.uploadMultipleFiles(images).then(() => {
            this.setState({
                ...this.state,
                isLoading: false,
                isLoadingUploadOther: false,
                offer: {
                    ...this.state.offer,
                    otherImages: this.props.otherImages
                }
            });
        }
        )
    }

    onDrop = acceptedFile => {
        return this.uploadBannerImage(acceptedFile)
    };

    onDropMultiple = acceptedFiles => {
        return this.uploadOtherImages(acceptedFiles);
    }

    deleteBannerImage = () => {
        this.setState({
            ...this.state,
            offer: {
                ...this.state.offer,
                bannerImage: undefined
            }
        })
    }

    deleteImage = (url) => {
        this.setState({
            isLoading: true,
            isLoadingUploadDelete: true
        });

        this.props.deleteImage(url);
        this.setState({
            ...this.state,
            isLoadingUploadDelete: false,
            offer: {
                ...this.state.offer,
                otherImages: this.props.otherImages
            }
        })

    }

    generateUniqueTitle = e => {
        let title = e.target.value;

        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        title = title.toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text

        this.setState({
            offer: { ...this.state.offer, uniqueTitle: title }
        });
    }

    handleCreateAd = e => {
        if (e) e.preventDefault();
        this.setState({ offer: { ...this.state.offer }, isLoading: true, formErrors: {} });

        let atLeastOnedescription = "";
        let atLeastOnekeywords = "";
        let newCategoryIfOther = "not empty";

        if (this.state.offer.description || this.state.offer.englishDescription) atLeastOnedescription = "true";
        if (this.state.offer.keywords || this.state.offer.englishKeywords) atLeastOnekeywords = "true";
        if (this.state.offer.category === 'other' && !this.state.offer.newCategory) newCategoryIfOther = "";

        const data = {
            ...this.state.offer,
            atLeastOnedescription,
            atLeastOnekeywords,
            newCategoryIfOther
        };

        checkAdFields(errorMessagesAd, data).then(() => {
            this.props.createOffer(this.state.offer).then(_ => {
                this.setState({
                    isLoading: false,
                    isCreated: true,
                    formErrors: {}
                });
                window.scrollTo(0, 0);
                this.props.history.push("/account");
            }
            ).catch(error => {
                let errors = {
                    unique_name: error.response.data.message.code === "E_UNIQUE" ? i18n.t('create_offer.unique_name_not_available') : undefined
                    //ADD ERROR CODES HERE
                };

                if (Object.values(errors).every(el => el === undefined)) {
                    errors.unknown = i18n.t('register_logic.unknown_error')
                };

                this.setState(state => ({
                    formErrors: {
                        ...state.formErrors,
                        register: errors
                    },
                    isLoading: false,
                }))
            })
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    register: errors
                },
                isLoading: false,
            }))
        })

    }

    render() {
        const { Layout, title } = this.props;

        return (
            <Layout
                offer={this.state.offer}
                handleUpdateAdInput={this.handleUpdateAdInput.bind(this)}
                handleEditorChange={this.handleEditorChange.bind(this)}
                handleCreateAd={this.handleCreateAd.bind(this)}
                generateUniqueTitle={this.generateUniqueTitle.bind(this)}
                categories={this.props.categories}
                formErrors={this.state.formErrors}
                isLoading={this.state.isLoading}
                isLoadingUpload={this.state.isLoadingUpload}
                isLoadingUploadOther={this.state.isLoadingUploadOther}
                onDrop={this.onDrop.bind(this)}
                onDropMultiple={this.onDropMultiple.bind(this)}
                deleteBanner={this.deleteBannerImage.bind(this)}
                deleteImage={this.deleteImage.bind(this)}
                state={this.state}
                title={title}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    categories: state.category.categories,
    user: state.user.user,
    bannerImage: state.user.bannerImage,
    otherImages: state.user.otherImages,
    userOffers: state.offer.userOffers
})

const mapDispatchToProps = {
    createOffer,
    getAllCategories,
    isConnected,
    uploadFile,
    uploadMultipleFiles,
    clearImages,
    deleteImage,
    getUserOffers
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);