import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginFacebook } from '../../actions/userAction';
import { getMultipleOffers, getFilteredOffersByCategory, getFilteredOffersBySearch, didReceiveData, receiveGeoData } from '../../actions/offerAction';
import { getAllCategories } from '../../actions/categoryAction';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: ""
        }
    }

    elmnt = document.getElementById("offerWrapper");

    UNSAFE_componentWillMount() {
        if (this.props.location && this.props.location.search.includes('code')) {
            let code = this.props.location.search.split('code=')[1];
            this.props.loginFacebook(code).then(response => {
                // this.setState({redirectToHome: true}, () => {
                //     this.setState({ redirectToHome: false});
                this.props.history.push("/account");
            }).catch(err => {
            })
        }
    }

    componentDidMount() {
        this.props.receiveGeoData({ lat: 45.508888, lng: -73.561668 });
        this.props.getMultipleOffers()
            .then(() => {
                this.props.didReceiveData(true);
                this.props.getAllCategories()
            })
            .catch(() => { });
    }

    filterCategory = category => {
        this.props.getFilteredOffersByCategory(category).then(() => {
            let elmnt = document.getElementById("offerWrapper");
            elmnt.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        })
    }

    filterSearch = e => {
        if (e) e.preventDefault();
        let elmnt = document.getElementById("offerWrapper");
        elmnt.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
        if (!this.state.keywords && !this.state.postal) {
            this.props.getMultipleOffers()
                .then(() => {
                    this.props.didReceiveData(true);
                    this.props.getAllCategories()
                })
                .catch(() => { });
        } else {
            this.props.getFilteredOffersBySearch(this.state.keywords, this.state.postal).then((invalidPostal) => {
                if (invalidPostal) {
                    this.setState({ error: "invalidPostal" });
                }
                if (!this.state.keywords && !this.state.postal) {
                    this.setState({ error: "emptySearch" });
                } else {
                    this.setState({ error: "" });
                }
            });
        }
    }

    handleUpdateInput = e => {
        if (e.target.name === "keywords") this.setState({ [e.target.name]: e.target.value.toLowerCase() });
        else if (e.target.name === "postal") this.setState({ [e.target.name]: e.target.value.toUpperCase() });
    }

    render() {
        const { Layout, title } = this.props;
        return (
            <Layout
                categories={this.props.categories}
                title={title}
                filterCategory={this.filterCategory.bind(this)}
                filterSearch={this.filterSearch.bind(this)}
                handleUpdateInput={this.handleUpdateInput.bind(this)}
                error={this.state.error}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    categories: state.category.categories
});

const mapDispatchToProps = {
    getMultipleOffers,
    getFilteredOffersByCategory,
    getFilteredOffersBySearch,
    getAllCategories,
    didReceiveData,
    receiveGeoData,
    loginFacebook
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);