import React, { Component } from 'react';
import { Label, Input } from 'reactstrap';
import AppConfig from "../../config/config";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { GoogleApiWrapper } from 'google-maps-react';
import Loading from '../../components/UI/Loading';
import i18n from '../../translations/i18n';

class LocationSearch extends Component {

    constructor(props) {

        super(props);

        this.state = {
            address: '',
            lat: undefined,
            lng: undefined,
            selected: undefined
        }
    }

    componentDidMount = () => {
        this.setState({
            address: this.props.value
        })
    }
    handleChange = address => {
        this.setState({
            address,
            selected: false
        });
    }

    handleSelect = address => {
        this.setState({
            selected: true
        })
        geocodeByAddress(address)
            .then(results => {
                if (!this.props.resetInput) this.setState({ address: results[0].formatted_address });
                getLatLng(results[0]).then(latLng => {
                    this.props.onSelect(results[0], latLng);
                    this.setState({
                        ...this.state,
                        lat: latLng.lat,
                        lng: latLng.lng
                    });
                })
            })
            .catch(error => console.error('Error', error));
    }

    render() {

        return (
            <PlacesAutocomplete
                // searchOptions={{ componentRestrictions: { country: ['ca'] } }}
                value={this.state.address}
                onChange={e => this.handleChange(e)}
                onSelect={e => this.handleSelect(e)}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        { this.props.displayLabel ? <Label className="" htmlFor="address">Address</Label> : undefined}
                        <Input
                            {...getInputProps({
                                placeholder: this.props.placeholder,
                                className: this.props.className
                            })}
                            autoComplete='none'
                            autoFocus={false}
                            value={this.state.address}
                        />
                        {
                            this.state.selected === false ?
                                <p className="labelWithText errormessagesmall">{i18n.t('global.select_address')}</p>
                                :
                                undefined
                        }

                        <div className="" style={{ position: "absolute", backgroundColor: "white", zIndex: "10", width: "100%" }}>
                            {loading && <Loading />}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? ''
                                    : '';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: AppConfig.googlePlaceAPIkey
})(LocationSearch);