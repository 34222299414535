import {
    RECEIVE_USER_DATA,
    REQUEST_USER_DATA,
    REGISTER_OK,
    RECEIVE_INTERCEPTOR_DATA,
    LOG_OUT,
    RECEIVE_USER_CARD_DATA,
    RECEIVE_USER_IMAGE_DATA,
    RECEIVE_MULTIPLE_USER_IMAGE_DATA,
    DELETE_IMAGE,
    DATA_SEND,
    CLEAR_IMAGES
} from '../actions/userAction';

import Store from '../store/userStore';

export const initialState = Store;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_USER_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case REGISTER_OK:
            return {
                ...state,
                isFetching: false,
            };
        case RECEIVE_USER_DATA:
            return {
                ...state,
                isFetching: false,
                token: action.payload.token ? action.payload.token : state.token,
                user: action.payload,
            };
        case RECEIVE_INTERCEPTOR_DATA:
            return {
                ...state,
                isFetching: false,
                interceptorId: action.payload,
            };
        case LOG_OUT:
            return {
                ...state,
                user: {
                    token: null
                },
            };
        case RECEIVE_USER_CARD_DATA:
            return {
                ...state,
                isFetching: false,
                card: action.payload,
            };
        case RECEIVE_USER_IMAGE_DATA:
            return {
                ...state,
                isFetching: false,
                bannerImage: action.payload,
            };
        case RECEIVE_MULTIPLE_USER_IMAGE_DATA:
            let url = action.payload;
            return {
                ...state,
                isFetching: false,
                otherImages: [...state.otherImages, url]
            };
        case CLEAR_IMAGES:
            return {
                ...state,
                isFetching: false,
                bannerImage: undefined,
                otherImages: []
            }
        case DELETE_IMAGE:
            let urlToDelete = action.payload;
            let newLocations = state.otherImages.filter(x => x !== urlToDelete);
            return {
                ...state,
                isFetching: false,
                otherImages: newLocations
            }
        case DATA_SEND:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}