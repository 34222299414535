import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';
import reducers from '../reducers/indexReducer';

const config = {
    key: 'root',
    storage,
    blacklist: ['status'],
};

const reducer = persistCombineReducers(config, reducers);

const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
        storage.removeItem('persist:root');
        state = undefined;
    }

    return reducer(state, action)
}

const middleware = [thunk];

const indexStore = () => {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        composeEnhancer(applyMiddleware(...middleware)),
    );

    const persistor = persistStore(
        store,
        null,
        () => {
            store.getState();
        },
    );

    return { persistor, store };
};

export default indexStore;
