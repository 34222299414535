import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOffer, removeOffer, contactForm } from '../../actions/offerAction';

class OfferSingle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: "",
                phone: "",
                marketing: false,
                offer: this.props.offer.id
            },
            isLoading: true,
            isDeleteOpen: false,
            activeIndex: 0,
            animating: false,
            success_sending_form: false
        }
    }

    componentDidMount() {
        const offerId = this.props.match && this.props.match.params ? this.props.match.params.id : this.props.route && this.props.route.params && this.props.route.params.id ? this.props.route.params.id : undefined;
        if (offerId) {
            this.props.getOffer(offerId)
                .then(res => {
                    if (res.payload.id) {
                        if (
                            this.props.offer
                            && this.props.offer.status !== 'approved'
                            && !this.props.user.user.token
                        ) {
                            this.props.history.push("/");
                        }

                        if (this.props.offer
                            && this.props.user.user.token
                            && this.props.offer.status !== 'approved'
                            && this.props.offer.user !== this.props.user.user.id) {
                            this.props.history.push("/");
                        }

                        this.setState({
                            isLoading: false,
                            sendingForm: false,
                            form: {
                                ...this.state.form,
                                offer: offerId
                            }
                        });
                    } else {
                        this.props.history.push("/");
                    }
                })
                .catch(err => {
                    this.props.history.push("/");
                })
        }
    }

    handleInput = e => {
        if (e.target.name === "contact") {
            if (e.target.value.match(/^\S+@\S+\.\S+$/)) {
                this.setState({ form: { ...this.state.form, email: e.target.value } });
            }
            if (e.target.value.match(/^\d+$/) && e.target.value.length >= 10) {
                this.setState({ form: { ...this.state.form, phone: e.target.value } });
            }
        } else if (e.target.name === "marketing") {
            this.setState({ form: { ...this.state.form, [e.target.name]: e.target.checked } });
        } else {
            this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } });
        }
    }

    sendContactForm = () => {
        this.setState({ sendingForm: true, success_sending_form: false, error_sending_form: false });

        this.props.contactForm(this.state.form)
            .then(_ => {
                this.setState({
                    sendingForm: false,
                    success_sending_form: true,
                    error_sending_form: false,
                    form: {
                        message: '',
                        name: '',
                        phone: '',
                        email: ''
                    }
                });
            })
            .catch(err => this.setState({
                sendingForm: false,
                success_sending_form: false,
                error_sending_form: true
            }));
    }

    toggleDelete = () => {
        this.setState({
            ...this.state,
            isDeleteOpen: !this.state.isDeleteOpen
        })
    }

    deleteOffer = (offerId) => {
        this.props.removeOffer(offerId);
        this.setState({
            ...this.state,
        })
    }

    setActiveIndex = (i) => {
        this.setState({
            activeIndex: i
        })
    }

    setAnimating = (animating) => {
        this.setState({
            animating: animating
        })
    }

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.offer.otherImages.length - 1 : this.state.activeIndex - 1;
        this.setActiveIndex(nextIndex);
    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.props.offer.otherImages.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setActiveIndex(nextIndex);
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setActiveIndex(newIndex);
    }

    render() {
        const { Layout } = this.props;

        return (
            <Layout
                isLoading={this.state.isLoading}
                offer={this.props.offer}
                deleteModal={this.state.isDeleteOpen}
                toggleDelete={this.toggleDelete.bind(this)}
                deleteOffer={this.deleteOffer.bind(this)}
                handleInput={this.handleInput.bind(this)}
                sendingForm={this.state.sendingForm}
                sendContactForm={this.sendContactForm.bind(this)}
                activeIndex={this.state.activeIndex}
                setAnimating={this.setAnimating.bind(this)}
                previous={this.previous.bind(this)}
                next={this.next.bind(this)}
                goToIndex={this.goToIndex.bind(this)}
                success_sending_form={this.state.success_sending_form}
                error_sending_form={this.state.error_sending_form}
                state={this.state}
            />
        )
    }
}

const mapStateToProps = state => ({
    offer: state.offer.offer,
    user: state.user
});

const mapDispatchToProps = {
    getOffer,
    removeOffer,
    contactForm
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferSingle);