import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkFields } from '../../config/utils';
import { errorMessagesLogin, errorMessagesForgotSend } from '../../config/messages';
import { login, forgotPassword, isConnected } from "../../actions/userAction";

class Login extends Component {
    state = {
        formErrors: {},
        isLoadingLogin: false,
        isOpenForgot: false,
    }

    componentDidMount = () => {
        if (this.props.location.search) {
            this.setState({
                email_confirm: this.props.location.search.includes("invalid") ? false : true
            })
        }

        this.props.isConnected().then(() => {
            this.props.history.push("/account");
        }).catch(() => { })
    }

    handleChangeInput = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleLogin = e => {
        if (e) e.preventDefault();
        this.setState({ isLoadingLogin: true, formErrors: {} });

        checkFields(errorMessagesLogin, this.state).then(() => {
            this.props.login(this.state.email, this.state.password).then(response => {
                this.setState({
                    user: response,
                    isLoadingLogin: false,
                    formErrors: {}
                })
                this.props.history.push("/account")
            }).catch(error => {
                this.setState({
                    ...this.state,
                    formErrors: {
                        ...this.state.formErrors,
                        login: error.message.message
                    },
                    isLoadingLogin: false,
                });
            })
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    login: errors
                },
                isLoadingLogin: false,
            }))
        })
    }

    toggleHandler = () => {
        this.setState({ formErrors: {} });
        this.props.toggle();
    }

    toggleForgot = () => {
        this.setState({
            formErrors: {},
            isOpenForgot: !this.state.isOpenForgot
        })
    }

    handleforgotPassword = (e) => {
        e.preventDefault();
        this.setState({
            loadingForgot: true,
            formErrors: {},
            sendForgot: false
        })
        checkFields(errorMessagesForgotSend, this.state).then(() => {
            this.props.forgotPassword(this.state.email).then(() => {
                this.setState({
                    loadingForgot: false,
                    sendForgot: true
                })
            }).catch(errors => {
                this.setState({
                    errorForgot: true
                })
            })
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    forgot: errors
                },
                loadingForgot: false,
            }))
        })
    }

    render() {
        const { Layout, isOpen, isLoading, title } = this.props;
        const { formErrors, isLoadingLogin } = this.state;
        return (
            <Layout
                isLoading={isLoading}
                isLoadingLogin={isLoadingLogin}
                isOpen={isOpen}
                isOpenForgot={this.state.isOpenForgot}
                formErrors={formErrors}
                email={this.state.email}
                password={this.state.password}
                toggle={this.toggleHandler.bind(this)}
                toggleForgot={this.toggleForgot.bind(this)}
                handleChangeInput={this.handleChangeInput.bind(this)}
                handleLogin={this.handleLogin.bind(this)}
                handleforgotPassword={this.handleforgotPassword.bind(this)}
                title={title}
                loadingForgot={this.state.loadingForgot}
                sendForgot={this.state.sendForgot}
                errorForgot={this.state.errorForgot}
                state={this.state}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching
});

const mapDispatchToProps = {
    login,
    forgotPassword,
    isConnected
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);