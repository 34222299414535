import Payment from "payment";

export const checkFields = (fields, data) => new Promise((resolve, reject) => {
    let errors = [];
    var hasNumber = /\d/;
    var hasLowercase = /[a-z]/;
    var hasUppercase = /[A-Z]/;
    var hasSpecialCharacter = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    var isNumeric = /[0-9]+/;
    var containsat = /[@]/;
    var containspoint = /[.]/;
    // var dateFormatElse = /d{4}-d{2}-d{2}/;
    var dateFormatSafari = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;
    // eslint-disable-next-line
    for (let i in fields) {

        // eslint-disable-next-line
        for (let j in fields[i]) {

            if (j === 'empty' && data[i] === '') {
                errors[i] = fields[i].empty;
                break;
            }
            if (j === 'isEmail' && (!containsat.test(data[i]) || !containspoint.test(data[i]))) {
                errors[i] = fields[i].isEmail;
                break;
            }
            if (j === 'isBirthDate' && data[i].length !== 10) {
                errors[i] = fields[i].isBirthDate;
                break;
            }
            if (j === 'isBirthDate' && dateFormatSafari.test(data[i])) {
                const day = parseInt(data[i].split("/")[0]);
                const month = parseInt(data[i].split("/")[1]);
                const year = parseInt(data[i].split("/")[2]);
                const actualYear = new Date().getFullYear();
                if (
                    day > 31
                    || day <= 0
                    || month <= 0
                    || month > 12
                    || year <= 0
                    || year > actualYear
                ) {
                    errors[i] = fields[i].isBirthDate;
                    break;
                }
            }
            if (j === 'format'
                && (data[i].length < 8
                    || data[i].length > 20
                    || !hasNumber.test(data[i])
                    || !hasLowercase.test(data[i])
                    || !hasUppercase.test(data[i])
                    || !hasSpecialCharacter.test(data[i]))) {
                errors[i] = fields[i].format;
                break;
            }
            if (j === 'numeric' && (!isNumeric.test(data[i]) || (data[i].length !== 12 && data[i].length !== 10) || (!data[i].startsWith('+336') && !data[i].startsWith('+337') && !data[i].startsWith('06') && !data[i].startsWith('07')))) {
                errors[i] = fields[i].numeric;
                break;
            }
        }
    }
    if (Object.keys(errors).length > 0) {
        return reject(errors);
    }
    resolve();
});

function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }
  
  export function formatCreditCardNumber(value) {
    if (!value) {
      return value;
    }
  
    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;
  
    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }
  
    return nextValue.trim();
  }
  
  export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;
  
    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer !== "amex" ? 4 : 3;
    }
  
    return clearValue.slice(0, maxLength);
  }
  
  export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);
  
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
  
    return clearValue;
  }
  
  export function formatFormData(data) {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
  }