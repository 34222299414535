import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import AppConfig from '../../config/config';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ForgotModal from './Forgot';
import Loading from '../UI/Loading';
import '../../styles/Pages/login.scss';


const LoginComponent = ({
    handleChangeInput,
    handleLogin,
    handleforgotPassword,
    formErrors,
    title,
    state,
    loadingForgot,
    sendForgot,
    errorForgot,
    isLoadingLogin }) => {

    document.title = title;
    const { t } = useTranslation();

    const [isOpenForgot, setIsOpenForgot] = useState(false);

    const openForgot = () => {
        setIsOpenForgot(!isOpenForgot);
    }

    return (
        <div id="loginContainer">
            <div id="formWrapper">
                {
                    state && state.email_confirm === true ?
                        <h6 className="success_confirm" >{t("login.confirmed_email")}</h6>
                        :
                        state.email_confirm === false ?
                            <h6 className="error_confirm">{t("login.confirmed_email_error")}</h6>
                            :
                            undefined
                }
                <div className="signUpTitle" style={{ textAlign: "center" }}>
                    <img className="logo" src={require("../../assets/logo/kwanuko_dragon.png")} alt="dragon logo" />
                    <h4 className="mt-3">{t('login.login_title')}</h4>
                    <hr />

                </div>

                <a href={AppConfig.baseUrlApi + '/facebook/auth'}>
                    <div className="text-center">
                        <button className="facebookConnect">
                            {t('login.login_facebook')}
                        </button>
                    </div>
                </a>
                <p className="text-uppercase mt-3 text-center">ou</p>

                <Form className="loginForm">
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="labelWithText" htmlFor="email">{t('register.email')}</Label>
                                <Input
                                    className={`${state && state.email ? 'fullinput' : ''}`}
                                    type="email" name="email" placeholder={t("login_placeholder.email")} autoComplete="username" onChange={e => handleChangeInput(e)}></Input>
                                <span className="errormessagesmall">
                                    {
                                        formErrors &&
                                            formErrors.login &&
                                            formErrors.login.email
                                            ?
                                            t(formErrors.login.email) : undefined
                                    }
                                </span>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="labelWithText" htmlFor="password">{t('register.password')}</Label>
                                <Input
                                    className={`${state && state.password ? 'fullinput' : ''}`}
                                    type="password"
                                    name="password"
                                    placeholder={t("login_placeholder.password")}
                                    autoComplete="current-password"
                                    onChange={e => handleChangeInput(e)}></Input>
                                <span className="errormessagesmall">
                                    {
                                        formErrors &&
                                            formErrors.login &&
                                            formErrors.login.password
                                            ?
                                            t(formErrors.login.password) : undefined}
                                </span>
                            </Col>
                        </Row>
                        <div className="forgot">
                            <div></div>
                            <span onClick={openForgot}>{t("login.forgot")}</span>
                        </div>
                    </FormGroup>

                    <span className="errormessagesmall text-center">
                        {
                            formErrors &&
                                formErrors.login &&
                                formErrors.login.code &&
                                formErrors.login.code === "E_MISSING_OR_INVALID_PARAMS"
                                ?
                                t('login.invalid_email')
                                :
                                (
                                    formErrors.login === "error.password.emailNotFound"
                                    ||
                                    formErrors.login === "error.password.incorrect"
                                )
                                    ?
                                    t('login.invalid_credentials')
                                    :
                                    (
                                        formErrors.login === "error.password.emailUnconfirmed"
                                    )
                                        ?
                                        t('login.unconfirmed_email')
                                        :
                                        formErrors.login && formErrors.login === undefined ?
                                            t('login.unknown_error')
                                            :
                                            undefined
                        }
                    </span>

                    <div className="text-center mt-3">
                        <button type="submit"
                            className={`${isLoadingLogin ? 'loading' : ''} text-uppercase mt-4 kwanukobutton`}
                            onClick={(e) => { handleLogin(e) }}>
                            {
                                isLoadingLogin ?
                                    <Loading button={true} />
                                    :
                                    t("login.login_email")
                            }
                        </button>


                        <Link to="/landing" className="registerLink d-block">{t("login.not_register")} <br /> {t("login.start_here")}</Link>
                    </div>

                </Form>


            </div>

            <ForgotModal
                handleChangeInput={handleChangeInput}
                handleforgotPassword={handleforgotPassword}
                isOpenForgot={isOpenForgot}
                openForgot={openForgot}
                loadingForgot={loadingForgot}
                sendForgot={sendForgot}
                formErrors={formErrors}
                errorForgot={errorForgot}
            />
        </div>
    )
}

export default LoginComponent;