import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr'
import {
    Card, CardImg, CardText, CardHeader, CardBody, CardFooter,
    CardTitle,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    Badge,
    FormText
} from 'reactstrap';
import { IconContext } from 'react-icons';
import { BiSearchAlt, BiEdit } from 'react-icons/bi';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import Loading from '../UI/Loading';
import LocationSearchInput from '../../containers/Utils/LocationSearchInput';
import Cards from 'react-credit-cards';

import { useTranslation } from 'react-i18next';
import '../../styles/Pages/userpage.scss';
import 'react-credit-cards/es/styles-compiled.css';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const UserPageComponent = ({
    user,
    handleUpdateInput,
    handleUpdateUser,
    isUpdating,
    title,
    toggleTab,
    activeTab,
    isUpdated,
    currentCard,
    focusedField,
    handleUpdateCurrentCard,
    handleFocusField,
    handleSubscribe,
    handleUpdateBilling,
    offers,
    toggleDelete,
    deleteModal,
    deleteAd,
    offerToBeDeleted,
    state,
    handleChangeInput,
    checkPasswordConfirmation,
    reset_success,
    handleResetPassword,
    loading_reset,
    handleSendSponsor,
    isAccepted,
    isValidated,
    acceptedSubscription,
    refusedSubscription,
    toggleRefuse,
    handleSubscriptionChange,
    handleConfirmSubscription,
    refuseText,
    refuseMessage,
    subscriptionOption,
    handleCancelSubscription,
    toggleUnsubscribe,
    unsubscribeModal,
    isActive,
    updatedBilling,
    subExpDate,
    handlePhoneInput,
    handlePaymentType,
    paymentType,
    emailSaved
}) => {

    document.title = title;
    const { t, i18n } = useTranslation();
    moment.locale('fr');

    const [isOpen, setIsOpen] = useState(false);
    const [acceptCGU, setAcceptCGU] = useState(false);
    const [acceptReponsability, setAcceptResponsability] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const toggleTabAndClose = (id) => {
        toggleTab(id);
        toggle();
    }

    let plan = ""
    switch (user && user.subscription && user.subscription.advertiserPlan) {
        case "Mensuel":
            plan = t("my_account.monthly");
            break;
        case "Annuel":
            plan = t("my_account.yearly");
            break;
        default:
            plan = "";
    }

    return (
        <div id="userMainContainer">
            <Nav tabs className="justify-content-center no_mobile">
                <NavItem>
                    <NavLink
                        className={(activeTab === 1) ? "active" : null}
                        onClick={() => toggleTab(1)}
                    >
                        {t("my_account.my_offers")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={(activeTab === 2) ? "active" : null}
                        onClick={() => toggleTab(2)}
                    >
                        {t("my_account.my_info")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={(activeTab === 3) ? "active" : null}
                        onClick={() => toggleTab(3)}
                    >
                        {t("my_account.support")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={(activeTab === 4) ? "active" : null}
                        onClick={() => toggleTab(4)}
                    >
                        {t("my_account.my_payment")}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={(activeTab === 5) ? "active" : null}
                        onClick={() => toggleTab(5)}
                    >
                        {t("my_account.my_subscription")}
                    </NavLink>
                </NavItem>
            </Nav>
            <div className="tab_selected_mobile" onClick={toggle}>
                <div className="tab_selected_mobile_content">
                    <span>
                        {
                            (activeTab === 1)
                                ?
                                t("my_account.my_offers")
                                :
                                (activeTab === 2) ?
                                    t("my_account.my_info")
                                    :
                                    (activeTab === 3) ?
                                        t("my_account.support")
                                        :
                                        (activeTab === 4) ?
                                            t("my_account.my_payment")

                                            :
                                            t("my_account.my_subscription")
                        }
                    </span>
                    {
                        isOpen ?
                            <IoIosArrowUp size={'2em'} className="arrowchevron" />
                            :
                            <IoIosArrowDown size={'2em'} className="arrowchevron" />
                    }
                </div>
                <div>
                    <Collapse isOpen={isOpen}>
                        <Nav tabs className="justify-content-center mobile_menu">
                            <NavItem>
                                <NavLink
                                    className={(activeTab === 1) ? "active" : null}
                                    onClick={() => toggleTabAndClose(1)}
                                >
                                    {t("my_account.my_offers")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={(activeTab === 2) ? "active" : null}
                                    onClick={() => toggleTabAndClose(2)}
                                >
                                    {t("my_account.my_info")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={(activeTab === 3) ? "active" : null}
                                    onClick={() => toggleTabAndClose(3)}
                                >
                                    {t("my_account.support")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={(activeTab === 4) ? "active" : null}
                                    onClick={() => toggleTabAndClose(4)}
                                >
                                    {t("my_account.my_payment")}
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={(activeTab === 5) ? "active" : null}
                                    onClick={() => toggleTabAndClose(5)}
                                >
                                    {t("my_account.my_subscription")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>

            </div>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={2}>
                    {
                        user && user.emailChangeCandidate ?
                            <div className="changeemailrequest" >
                                <p>
                                    {t('my_account.new_email_asked')}<br />
                                    {t('my_account.new_email_asked_2')}
                                </p>
                                <div className="m-auto text-center">
                                    <span className="bold">
                                        {t('my_account.actual_email')}
                                    </span>
                                    <span className="regular">{emailSaved}</span>
                                    <br />
                                    <span className="bold">
                                        {t('my_account.new_email')}
                                    </span>
                                    <span className="regular">{user.emailChangeCandidate}</span>
                                </div>

                            </div>
                            :
                            undefined
                    }
                    <div className="infoContainer">

                        <Form>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="lastname">{t('register.last_name')}</Label>
                                        <Input
                                            type="text"
                                            name="lastname"
                                            onChange={e => handleUpdateInput(e)}
                                            value={user.lastname}
                                            placeholder={t("register_placeholder.last_name")}
                                            className={`${user && user.lastname ? 'fullinput' : ''}`}
                                        />
                                    </Col>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="firstname">{t('register.first_name')}</Label>
                                        <Input
                                            type="text"
                                            name="firstname"
                                            onChange={e => handleUpdateInput(e)}
                                            value={user.firstname}
                                            placeholder={t("register_placeholder.first_name")}
                                            className={`${user && user.firstname ? 'fullinput' : ''}`}></Input>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="email">{t('register.email')}</Label>
                                        <Input
                                            type="email"
                                            name="emailAddress"
                                            onChange={e => handleUpdateInput(e)}
                                            value={user.emailAddress}
                                            placeholder={t("register_placeholder.email")}
                                            className={`${user && user.emailAddress ? 'fullinput' : ''}`}
                                        />

                                    </Col>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="phone">{t('register.phone')}</Label>
                                        <PhoneInput
                                            country={'ca'}
                                            value={user.phone}
                                            onChange={phone => handlePhoneInput(phone)}
                                            inputClass={`${user && user.phone ? 'fullinput' : ''}`}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="address">{t('register.address')}</Label>
                                        <LocationSearchInput
                                            displayLabel={false}
                                            placeholder={t("register_placeholder.address")}
                                            name="address"
                                            value={user.address}
                                            onChange={e => handleUpdateInput(e)}
                                            className={`${user && user.address ? 'fullinput' : ''}`}
                                            onSelect={e => {
                                                handleUpdateInput({ target: { name: "address", value: e.formatted_address } });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="intro">{t('register.introduction')}</Label>
                                        <Input
                                            type="textarea"
                                            name="intro"
                                            onChange={e => handleUpdateInput(e)}
                                            value={user.intro}
                                            placeholder={t("register_placeholder.introduction")}
                                            className={`${user && user.intro ? 'fullinput' : ''}`}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="area">{t('register.area_title')}</Label>
                                        <Input
                                            type="text"
                                            name="area"
                                            onChange={e => handleUpdateInput(e)}
                                            value={user.area}
                                            placeholder={t("register_placeholder.area_description")}
                                            className={`${user && user.area ? 'fullinput' : ''}`}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <div className="registerButtonWrapper" style={{ textAlign: "center" }}>
                                {isUpdated ?
                                    <p className="updated">{t('my_account.updated')}</p>
                                    :
                                    undefined
                                }
                                {
                                    state && state.formErrors && state.formErrors.update && state.formErrors.update.existing_user ?
                                        <span className="errormessagesmall">{state.formErrors.update.existing_user}</span>
                                        :
                                        undefined
                                }
                                <Button
                                    onClick={e => handleUpdateUser(e)}
                                    className={`${isUpdating ? 'loading' : ''} text-uppercase mt-4 kwanukobutton`}
                                >
                                    {
                                        isUpdating ?
                                            <Loading button={true} />
                                            :
                                            t('my_account.save')
                                    }
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <div className="infoContainer pt-0">
                        <h5 className="text-center">{t('global.reset_password')}</h5>
                        <Form>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="password">{t('register.password')}</Label>
                                        <Input
                                            className={`${state && state.password ? 'fullinput' : ''}`}
                                            type="password" name="password" placeholder={t("password_reset.new_password_placeholder")} autoComplete="username" onChange={e => handleChangeInput(e)}></Input>
                                        <span className="errormessagesmall">
                                            {
                                                state &&
                                                    state.formErrors.reset &&
                                                    state.formErrors.reset.password
                                                    ?
                                                    t(state.formErrors.reset.password) : undefined}
                                        </span>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label className="labelWithText" htmlFor="confirmPassword">{t('register.password_confirmation')}</Label>
                                        <Input
                                            className={`${state && state.passwordConfirmation ? 'fullinput' : ''}`}
                                            type="password"
                                            name="passwordConfirmation"
                                            placeholder={t("password_reset.new_password_confirmation_placeholder")}
                                            autoComplete="current-password"
                                            onChange={e => handleChangeInput(e)}
                                            onBlur={checkPasswordConfirmation}></Input>
                                        <span className="errormessagesmall">
                                            {
                                                state &&
                                                    state.formErrors.reset &&
                                                    state.formErrors.reset.passwordConfirmation
                                                    ?
                                                    t(state.formErrors.reset.passwordConfirmation) : undefined}
                                        </span>
                                        <span className="errormessagesmall">
                                            {
                                                state &&
                                                    !state.check_confirmation
                                                    ?
                                                    t('forgot.not_similar') : undefined}
                                        </span>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <div className="text-center mt-3">
                                {
                                    reset_success ?
                                        <p className="confirmationmessagesmall">{t('forgot.success_reset')}</p>
                                        :
                                        undefined
                                }
                                {
                                    state.reset_error ?
                                        <p className="errormessagesmall">{t('my_account.reset_error')}</p>
                                        :
                                        undefined
                                }
                                <button
                                    type="submit"
                                    onClick={(e) => { handleResetPassword(e) }}
                                    className={`kwanukobutton ${loading_reset ? 'loading' : undefined}`}>
                                    {
                                        loading_reset ?
                                            <Loading button={true} />
                                            :
                                            t("password_reset.submit")
                                    }
                                </button>
                            </div>
                        </Form>
                    </div>
                </TabPane>

                <TabPane tabId={3}>
                    {isAccepted ?
                        // isValidated ?
                        acceptedSubscription ?
                            isActive ?
                                <>
                                    <div className="infoContainer supportContainer">
                                        <div className="text-center m-auto supportContainerTitle">
                                            <h5 className="mb-2 w-100">{t('my_account.support_text')}</h5>
                                            <p>{t('my_account.support_text_description')}</p>
                                            <p>{t('my_account.support_text_explain')}</p>
                                            <p className="bold">{t("my_account.sponsor_code")}: <span className="grey">{user && user.sponsorCode}</span></p>
                                            <hr />
                                        </div>
                                        <Form>
                                            <FormGroup>
                                                <Row>
                                                    <Col>
                                                        <Label className="labelWithText" htmlFor="sponsorName">{t("my_account.children_name")}</Label>
                                                        <Input
                                                            className={`${state && state.sponsorName ? 'fullinput' : ''}`}
                                                            type="text"
                                                            name="sponsorName"
                                                            placeholder={t("my_account.children_name_placeholder")}
                                                            onChange={e => handleChangeInput(e)}
                                                        ></Input>
                                                        <span className="errormessagesmall">
                                                            {
                                                                state &&
                                                                    state.formErrors.sponsor &&
                                                                    state.formErrors.sponsor.sponsorName
                                                                    ?
                                                                    t(state.formErrors.sponsor.sponsorName) : undefined}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <Label className="labelWithText" htmlFor="sponsorEmail">{t("my_account.children_email")}</Label>
                                                        <Input
                                                            className={`${state && state.sponsorEmail ? 'fullinput' : ''}`}
                                                            type="email"
                                                            name="sponsorEmail"
                                                            placeholder={t("my_account.children_email_placeholder")}
                                                            onChange={e => handleChangeInput(e)}
                                                        ></Input>
                                                        <span className="errormessagesmall">
                                                            {
                                                                state &&
                                                                    state.formErrors.sponsor &&
                                                                    state.formErrors.sponsor.sponsorEmail
                                                                    ?
                                                                    t(state.formErrors.sponsor.sponsorEmail) : undefined
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </FormGroup>

                                            <div className="text-center mt-3">
                                                {
                                                    state.success_sponsor ?
                                                        <p className="confirmationmessagesmall">{t('my_account.success_sponsor')}</p>
                                                        :
                                                        <button
                                                            type="submit"
                                                            onClick={(e) => { handleSendSponsor(e) }}
                                                            className={`kwanukobutton ${state.loading_sponsor ? 'loading' : undefined}`}>
                                                            {
                                                                state.loading_sponsor ?
                                                                    <Loading button={true} />
                                                                    :
                                                                    t("my_account.send")
                                                            }
                                                        </button>
                                                }
                                                {
                                                    state.sponsor_error ?
                                                        <p className="errormessagesmall">{t('my_account.sponsor_error')}</p>
                                                        :
                                                        undefined
                                                }
                                            </div>
                                        </Form>
                                    </div>
                                </>
                                :
                                user && user.paymentType === "credit_card" ?
                                    <>
                                        <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_accept")}{moment(subExpDate).format('LLL')}{t("register_logic.subscription_accept_next")} <span className="tab_link" onClick={() => toggleTab(4)}>"{t("register_logic.credit_card")}"</span>.</h5>
                                        <h5 className="text-center">{t("register_logic.subscription_accept_next_2")}</h5>
                                    </>
                                    :
                                    user.paymentType === "payment_paypal" ?
                                        <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_paypal")}</h5>
                                        :
                                        user.paymentType === "payment_interac" ?
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_interac")}</h5>
                                            :
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_post")}</h5>
                            :
                            refusedSubscription ?
                                <>
                                    <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_decline")}</h5>

                                </>
                                :
                                <>
                                    <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_proposal_other_tab")}<span className="tab_link" onClick={() => toggleTab(5)}> "{t("register_logic.email_validated_next_subscription")}"</span></h5>
                                </>
                        :
                        <>
                            <h5 className="text-center mt-5 pt-5">{t("register_logic.email_validated")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next_2")} <span className="tab_link" onClick={() => toggleTab(5)}>"{t("register_logic.email_validated_next_subscription")}"</span>.</h5>
                        </>


                    }
                </TabPane>

                <TabPane tabId={4}>
                    {isAccepted ?
                        // isValidated ?
                        acceptedSubscription && user && user.paymentType === "credit_card" ?
                            <>
                                <div className="paymentContainer">
                                    <Form>
                                        <FormGroup className="text-center">
                                            <Label className="text-uppercase">{t("my_account.my_card")}</Label>
                                            <div>
                                                <div className="mb-3">
                                                    <Cards
                                                        cvc={currentCard.cvc ? currentCard.cvc : ''}
                                                        expiry={currentCard.expiry ? currentCard.expiry : ''}
                                                        name={currentCard.name ? currentCard.name : ''}
                                                        number={currentCard.number ? currentCard.number : ''}
                                                        focused={focusedField}
                                                        locale={{ valid: "" }}
                                                        placeholders={{ name: "John Doe" }}
                                                    />
                                                </div>
                                                <div className="card_inputs">
                                                    <Row className="mb-2 mx-0">
                                                        <Col className="p-0">
                                                            <Input
                                                                type="text"
                                                                placeholder={t("my_account.name")}
                                                                onFocus={e => { handleFocusField(e) }}
                                                                name="name"
                                                                value={currentCard.name ? currentCard.name : ''}
                                                                onChange={e => handleUpdateCurrentCard(e)}
                                                                className={`${currentCard.name ? 'fullinput' : ''}`}
                                                            ></Input>
                                                        </Col>
                                                    </Row>

                                                    {(currentCard.number.includes("*")) ?
                                                        <Row className="mr-0 ml-0">
                                                            <p
                                                                style={{ color: "green", fontSize: "small", textAlign: "initial" }}
                                                                className="mb-0 font-weight-light">{t("my_account.rewrite_number")}</p>
                                                        </Row>
                                                        :
                                                        undefined
                                                    }

                                                    <Row className="mb-2 mx-0">
                                                        <Col className="p-0">
                                                            <Input
                                                                type="text"
                                                                placeholder={t("my_account.number")}
                                                                onFocus={e => { handleFocusField(e) }}
                                                                name="number"
                                                                value={currentCard.number ? currentCard.number : ''}
                                                                onChange={e => handleUpdateCurrentCard(e)}
                                                                className={`${currentCard.number ? 'fullinput' : ''}`}
                                                            ></Input>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb-2 mx-0 expiry">
                                                        <Col className="pl-0">
                                                            <Input
                                                                type="text"
                                                                placeholder={t("my_account.expiry")}
                                                                onFocus={e => { handleFocusField(e) }}
                                                                name="expiry"
                                                                value={currentCard.expiry ? currentCard.expiry : ''}
                                                                onChange={e => handleUpdateCurrentCard(e)}
                                                                className={`${currentCard.expiry ? 'fullinput' : ''}`}
                                                            ></Input>
                                                        </Col>

                                                        <Col className="p-0">
                                                            <Input
                                                                type="text"
                                                                placeholder={t("my_account.cvc")}
                                                                onFocus={e => { handleFocusField(e) }}
                                                                name="cvc" value={currentCard.cvc ? currentCard.cvc : ''}
                                                                onChange={e => handleUpdateCurrentCard(e)}
                                                                className={`${currentCard.cvc ? 'fullinput' : ''}`}
                                                            ></Input>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mx-0 pt-3">
                                                        {isActive ?
                                                            <>
                                                                <Button className={`${state.updating_billing ? 'loading' : ''} text-uppercase mt-4 m-auto kwanukobutton`}
                                                                    onClick={e => handleUpdateBilling(e)}
                                                                    disabled={(!currentCard.cvc || !currentCard.cvc.match(/([0-9]{3})/)) ||
                                                                        (!currentCard.expiry || !currentCard.expiry.match(/(0[1-9]|10|11|12)\/[0-9]{2}/)) ||
                                                                        (!currentCard.number || !currentCard.number.replaceAll(' ', '').match(/([0-9]{16})/)) ||
                                                                        (!currentCard.name || !currentCard.name.match(/([a-z]{2,}) ([a-z]{2,})/i))}>
                                                                    {
                                                                        state.updating_billing ?
                                                                            <Loading button={true} />
                                                                            :
                                                                            t("my_account.update")

                                                                    }
                                                                </Button>

                                                                <p className="mt-2 success">{t("register_logic.full_access")}</p>
                                                            </>
                                                            :
                                                            <>
                                                                <Button className={`${state.updating_billing ? 'loading' : ''} text-uppercase mt-4 m-auto kwanukobutton`}
                                                                    onClick={e => handleSubscribe(e)}
                                                                    disabled={(!currentCard.cvc || !currentCard.cvc.match(/([0-9]{3})/)) ||
                                                                        (!currentCard.expiry || !currentCard.expiry.match(/(0[1-9]|10|11|12)\/[0-9]{2}/)) ||
                                                                        (!currentCard.number || !currentCard.number.replaceAll(' ', '').match(/([0-9]{16})/)) ||
                                                                        (!currentCard.name || !currentCard.name.match(/([a-z]{2,}) ([a-z]{2,})/i))}>
                                                                    {
                                                                        state.updating_billing ?
                                                                            <Loading button={true} />
                                                                            :
                                                                            t("my_account.subscribe")
                                                                    }
                                                                </Button>
                                                            </>}
                                                        {updatedBilling === true ?
                                                            <>
                                                                <p className="success mt-2">{t("my_account.updated_card")}</p>
                                                            </>
                                                            :
                                                            updatedBilling === false ?
                                                                <span className="errormessagesmall mt-2">{t('my_account.error_creditcard')}</span>
                                                                :
                                                                undefined
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </>
                            :
                            user.status === "active" ?
                                <h5 className="text-center mt-5 pt-5">
                                    {
                                        user.paymentType === "payment_paypal" ?
                                            t("register_logic.success_paypal")
                                            :
                                            user.paymentType === "payment_interac" ?
                                                t("register_logic.success_interac")
                                                :
                                                t("register_logic.success_post")
                                    }
                                </h5>
                                :
                                user.status === "payment_pending" ?
                                    <h5 className="text-center mt-5 pt-5">
                                        {
                                            user.paymentType === "payment_paypal" ?
                                                t("register_logic.subscription_paypal")
                                                :
                                                user.paymentType === "payment_interac" ?
                                                    t("register_logic.subscription_interac")
                                                    :
                                                    t("register_logic.subscription_post")
                                        }
                                    </h5>
                                    :
                                    refusedSubscription ?
                                        <>
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_decline")}</h5>

                                        </>
                                        :
                                        <>
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_proposal_other_tab")} <span className="tab_link" onClick={() => toggleTab(5)}> "{t("register_logic.email_validated_next_subscription")}"</span></h5>
                                        </>
                        :
                        <>
                            <h5 className="text-center mt-5 pt-5">{t("register_logic.email_validated")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next_2")} <span className="tab_link" onClick={() => toggleTab(5)}>"{t("register_logic.email_validated_next_subscription")}"</span></h5>
                        </>

                    }
                </TabPane>

                <TabPane tabId={5}>
                    {isAccepted ?
                        // isValidated ?
                        acceptedSubscription ?
                            isActive ?
                                <>
                                    <Card className="subCard mt-5">
                                        <CardHeader tag="h6">{user && user?.subscription && i18n.lang === 'fr' ? user.subscription.name : user.subscription.englishName }</CardHeader>
                                        <CardBody>
                                            <p>
                                                <span className="price">
                                                    {user && user.subscription && user.subscription.planPrice}$CAD
                                                </span>
                                                <span className="price_device">/{plan}</span>
                                                <span className="d-block">{t('my_account.before_taxes')}</span>
                                            </p>
                                            <p>{user && user?.subscription && i18n.lang === 'fr' ? user.subscription.description : user.subscription.englishDescription }</p>
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <button className="kwanukobutton" onClick={toggleUnsubscribe}>{t('my_account.unsubscribe')}</button>
                                        </CardFooter>
                                    </Card>

                                    <Modal isOpen={unsubscribeModal} toggle={toggleUnsubscribe} className="delete_offer_modal">
                                        <ModalHeader toggle={toggleUnsubscribe}>{t('my_account.unsubscribe_title')}</ModalHeader>
                                        <ModalBody>
                                            {t('my_account.unsubscribe_description')}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" className="kwanukobutton" onClick={() => handleCancelSubscription()}>{t('my_account.unsubscribe')}</Button>{' '}
                                            <Button color="secondary" onClick={toggleUnsubscribe} className="kwanukobuttoncancel">{t('my_account.confirm_delete_cancel')}</Button>
                                        </ModalFooter>
                                    </Modal>
                                </>
                                :
                                user && user.paymentType === "credit_card" ?
                                    <>
                                        <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_accept")}{moment(subExpDate).format('LLL')}{t("register_logic.subscription_accept_next")} <span className="tab_link" onClick={() => toggleTab(4)}>"{t("register_logic.credit_card")}"</span>.</h5>
                                        <h5 className="text-center">{t("register_logic.subscription_accept_next_2")}</h5>
                                    </>
                                    :
                                    user.paymentType === "payment_paypal" ?
                                        <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_paypal")}</h5>
                                        :
                                        user.paymentType === "payment_interac" ?
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_interac")}</h5>
                                            :
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_post")}</h5>
                            :
                            refusedSubscription ?
                                <>
                                    <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_decline")}</h5>

                                </>
                                :
                                <>
                                    <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_proposal")}</h5>
                                    <h5 className="text-center">{t("register_logic.subscription_answer")}</h5>
                                    <Card className="subCard">
                                        <CardHeader tag="h6">{user && user.subscription && user.subscription.name}</CardHeader>
                                        <CardBody className="text-center borderBottom">
                                            <CardBody>
                                                <p>
                                                    <span className="price">
                                                        {user && user.subscription && user.subscription.planPrice}$CAD
                                                </span>
                                                    <span className="price_device">/{plan}</span>
                                                    <span className="d-block">{t('my_account.before_taxes')}</span>
                                                </p>
                                                <p>{user && user.subscription && user.subscription.description}</p>
                                            </CardBody>
                                        </CardBody>
                                        <CardBody>
                                            <Form className="accept_form">
                                                <FormGroup className="checkbox_cgu">
                                                    <label className="checkbox bounce">
                                                        <input type="checkbox" name="cgu" id="cgu" onChange={() => setAcceptCGU(!acceptCGU)} />
                                                        <svg viewBox="0 0 21 21">
                                                            <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                                        </svg>
                                                    </label>
                                                    <label className="ml-2" htmlFor="cgu">{t('register_logic.cgu_checkbox')} <Link to="/terms" className="cgu_link">{t('register_logic.cgu')}</Link></label>
                                                </FormGroup>
                                                <FormGroup className="checkbox_cgu">
                                                    <label className="checkbox bounce">
                                                        <input type="checkbox" name="responsability" id="responsability" onChange={() => setAcceptResponsability(!acceptReponsability)} />
                                                        <svg viewBox="0 0 21 21">
                                                            <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                                        </svg>
                                                    </label>
                                                    <label className="ml-2" htmlFor="responsability">{t('register_logic.responsability_checkbox')}</label>
                                                </FormGroup>

                                                <FormGroup check onChange={e => toggleRefuse(e)}>
                                                    {/* <Input type="radio" id="accept" name="sub" value="accept" />
                                                    <Label for="accept">{t("register_logic.accept")}</Label><br />
                                                    <Input type="radio" id="refuse" name="sub" value="refuse" />
                                                    <Label for="refuse">{t("register_logic.refuse")}</Label> */}
                                                    <div className="choice_buttons">
                                                        <Button className={`kwanukobutton ${state.subscriptionOption === 'accept' ? 'loading' : ''}`} onClick={e => toggleRefuse(e)} value="accept">{t('register_logic.accept')}</Button>
                                                        <Button className={`kwanukobutton ${state.subscriptionOption === 'refuse' ? 'loading' : ''}`} onClick={e => toggleRefuse(e)} value="refuse">{t('register_logic.refuse')}</Button>
                                                    </div>
                                                </FormGroup>
                                                {refuseText ?
                                                    <>
                                                        <Input type="text" name="reason" onChange={e => handleSubscriptionChange(e)} placeholder={t("register_logic.refusal_reason")} />
                                                    </>
                                                    :
                                                    undefined}

                                            </Form>
                                        </CardBody>
                                        {
                                            state.subscriptionOption === 'accept' ?

                                                <CardBody>
                                                    <Form className="accept_form">
                                                        <h6 className="mb-4">{t('register_logic.payment_method')}</h6>
                                                        <FormGroup className="checkbox_payment">
                                                            <label className="checkbox bounce">
                                                                <input type="radio" name="payment_method" id="credit_card" value="credit_card" onChange={(e) => handlePaymentType(e)} />
                                                                <svg viewBox="0 0 21 21">
                                                                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                                                </svg>
                                                            </label>
                                                            <label className="ml-2" htmlFor="credit_card">{t('register_logic.credit_card_payment')}</label>
                                                        </FormGroup>
                                                        <FormText color="muted">
                                                            {t('register_logic.bank_expl')}
                                                        </FormText>
                                                        <FormGroup className="checkbox_payment">
                                                            <label className="checkbox bounce">
                                                                <input type="radio" name="payment_method" id="payment_interac" value="payment_interac" onChange={(e) => handlePaymentType(e)} />
                                                                <svg viewBox="0 0 21 21">
                                                                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                                                </svg>
                                                            </label>
                                                            <label className="ml-2" htmlFor="payment_interac">{t('register_logic.interac')}</label>
                                                        </FormGroup>
                                                        <FormText color="muted">
                                                            {t('register_logic.rest_expl')}
                                                        </FormText>
                                                        <FormGroup className="checkbox_payment">
                                                            <label className="checkbox bounce">
                                                                <input type="radio" name="payment_method" id="post" value="post" onChange={(e) => handlePaymentType(e)} />
                                                                <svg viewBox="0 0 21 21">
                                                                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                                                </svg>
                                                            </label>
                                                            <label className="ml-2" htmlFor="post">{t('register_logic.post')}</label>
                                                        </FormGroup>
                                                        <FormText color="muted">
                                                            {t('register_logic.rest_expl')}
                                                        </FormText>
                                                        <FormGroup className="checkbox_payment">
                                                            <label className="checkbox bounce">
                                                                <input type="radio" name="payment_method" id="payment_paypal" value="payment_paypal" onChange={(e) => handlePaymentType(e)} />
                                                                <svg viewBox="0 0 21 21">
                                                                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                                                </svg>
                                                            </label>
                                                            <label className="ml-2" htmlFor="payment_paypal">{t('register_logic.paypal')}</label>
                                                        </FormGroup>
                                                        <FormText color="muted">
                                                            {t('register_logic.rest_expl')}
                                                        </FormText>
                                                        {refuseText ?
                                                            <>
                                                                <Input type="text" name="reason" onChange={e => handleSubscriptionChange(e)} placeholder={t("register_logic.refusal_reason")} />
                                                            </>
                                                            :
                                                            undefined}

                                                    </Form>
                                                </CardBody>
                                                :
                                                undefined
                                        }
                                        <CardFooter className="text-center">
                                            <Button
                                                className={`${state && state.isSuscribing ? 'loading' : ''} text-uppercase kwanukobutton`}
                                                disabled={acceptCGU !== true || acceptReponsability !== true || (!subscriptionOption || (subscriptionOption === "refuse" && !refuseMessage)) || (subscriptionOption === "accept" && !paymentType)}
                                                onClick={() => handleConfirmSubscription()}>
                                                {
                                                    state && state.isSuscribing ?
                                                        <Loading button={true} />
                                                        :
                                                        t("register_logic.confirm")
                                                }
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </>
                        :
                        <>
                            <h5 className="text-center mt-5 pt-5">{t("register_logic.email_validated")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next_2")} <span className="tab_link" onClick={() => toggleTab(5)}>"{t("register_logic.email_validated_next_subscription")}"</span></h5>
                        </>
                        // :
                        // <>
                        //     <h5 className="text-center mt-5 pt-5">{t("register_logic.user_not_validated")}</h5>
                        //     <h5 className="text-center">{t("register_logic.user_not_validated_2")}</h5>
                        // </> */
                    }

                </TabPane>
                <TabPane tabId={1}>
                    {isAccepted ?
                        // isValidated ?
                        acceptedSubscription ?
                            isActive ?
                                <>
                                    {
                                        offers && offers.length < 1 ?
                                            <Link to='/offercreate'><button className="newAdButton text-uppercase kwanukobutton">{t("my_account.create_offer")}</button></Link>
                                            :
                                            undefined
                                    }

                                    <div className={`${offers && offers.length ? 'offersContainer' : 'offersContainerEmpty'}`}>
                                        {offers && offers.length ? offers.map((offer, index) => {
                                            return (
                                                <Card key={index}>
                                                    <div className="position-relative">
                                                        <CardImg top width="100%" src={offer.bannerImage} alt="Card image cap" />
                                                        <div className="showonhover">
                                                            <IconContext.Provider value={{ color: "white", size: "2em" }}>
                                                                <div>
                                                                    <Link to={`/pages/${offer.uniqueTitle}`}><BiSearchAlt /></Link>
                                                                </div>
                                                            </IconContext.Provider>
                                                            <IconContext.Provider value={{ color: "white", size: "2em", className: "updateIcon" }}>
                                                                <div>
                                                                    <Link to={`/offeredit/${offer.uniqueTitle}`}><BiEdit /></Link>
                                                                </div>
                                                            </IconContext.Provider>
                                                            <IconContext.Provider value={{ color: "white", size: "2em", className: "deleteIcon" }}>
                                                                <div>
                                                                    <RiDeleteBin2Line onClick={() => toggleDelete(offer.id)} />
                                                                </div>
                                                            </IconContext.Provider>
                                                        </div>
                                                    </div>

                                                    <CardBody className="card-body">
                                                        <span className="text-secondary mt-2">{offer.category && (offer.category.title || offer.category.englishTitle ) ? ( i18n.language === 'fr' ? offer.category.title : offer.category.englishTitle ) : t('my_account.else')} {offer.newCategory ? offer.newCategory + t('my_account.waiting_category_approval') : null}</span>
                                                        <CardTitle className="card-title">{ i18n.language === 'fr' ? offer.title : offer.englishTitle }</CardTitle>
                                                        <CardText>{t('create_offer.offer_price')}:
                                                            <span className="bold ml-1">{offer.price.toFixed(2)} $CAD</span>
                                                        </CardText>
                                                        <span className="text-secondary mt-2">{t('my_account.created_at')} {moment(offer.createdAt).format('LLL')}</span>
                                                        {
                                                            offer && offer.status ?
                                                                <div className="d-flex align-items-center mt-2 offer_status">
                                                                    <span className="bold ml-1">{t('my_account.offer_status')}:</span>

                                                                    {
                                                                        offer.status === 'waiting_approval' ?
                                                                            <Badge color="warning" className="ml-1">{t('my_account.offer_status_waiting')}</Badge>
                                                                            :
                                                                            offer.status === 'modified_waiting_approval' ?
                                                                                <Badge color="warning" className="ml-1">{t('my_account.offer_status_modify')}</Badge>
                                                                                :
                                                                                offer.status === 'approved'
                                                                                    ?
                                                                                    <Badge color="success" className="ml-1">{t('my_account.offer_status_validated')}</Badge>
                                                                                    :
                                                                                    <Badge color="danger" className="ml-1">{t('my_account.offer_status_refute')}</Badge>
                                                                    }
                                                                </div>
                                                                :
                                                                undefined
                                                        }

                                                        {
                                                            offer && offer.refuseReason && offer.status === "denied" ?
                                                                <span className="bold ml-1">{t('my_account.refute_expl')}: {offer.refuseReason}</span>
                                                                :
                                                                undefined
                                                        }


                                                    </CardBody>
                                                </Card>
                                            )
                                        })
                                            :
                                            undefined
                                        }
                                    </div>
                                    {(offers.length === 0) ?
                                        <div className="text-center no_offers">
                                            <p className="text-center mt-4 bold">{t('global.no_offers_yet')}</p>
                                        </div>
                                        :
                                        undefined}

                                    <Modal isOpen={deleteModal} toggle={toggleDelete} className="delete_offer_modal">
                                        <ModalHeader toggle={toggleDelete}>{t('my_account.confirm_delete_title')}</ModalHeader>
                                        <ModalBody>
                                            {t('my_account.confirm_delete_description')}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" className="kwanukobutton" onClick={() => deleteAd(offerToBeDeleted)}>{t('my_account.confirm_delete_button')}</Button>{' '}
                                            <Button color="secondary" onClick={toggleDelete} className="kwanukobuttoncancel">{t('my_account.confirm_delete_cancel')}</Button>
                                        </ModalFooter>
                                    </Modal>
                                </>
                                :
                                user && user.paymentType === "credit_card" ?
                                    <>
                                        <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_accept")}{moment(subExpDate).format('LLL')}{t("register_logic.subscription_accept_next")} <span className="tab_link" onClick={() => toggleTab(4)}>"{t("register_logic.credit_card")}"</span>.</h5>
                                        <h5 className="text-center">{t("register_logic.subscription_accept_next_2")}</h5>
                                    </>
                                    :
                                    user.paymentType === "payment_paypal" ?
                                        <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_paypal")}</h5>
                                        :
                                        user.paymentType === "payment_interac" ?
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_interac")}</h5>
                                            :
                                            <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_post")}</h5>
                            :
                            refusedSubscription ?
                                <>
                                    <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_decline")}</h5>

                                </>
                                :
                                <>
                                    <h5 className="text-center mt-5 pt-5">{t("register_logic.subscription_proposal_other_tab")}<span className="tab_link" onClick={() => toggleTab(5)}> "{t("register_logic.email_validated_next_subscription")}"</span></h5>
                                </>
                        :
                        <>
                            <h5 className="text-center mt-5 pt-5">{t("register_logic.email_validated")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next")}</h5>
                            <h5 className="text-center">{t("register_logic.email_validated_next_2")} <span className="tab_link" onClick={() => toggleTab(5)}>"{t("register_logic.email_validated_next_subscription")}"</span></h5>
                        </>
                        // :
                        // <>
                        //     <h5 className="text-center mt-5 pt-5">{t("register_logic.user_not_validated")}</h5>
                        //     <h5 className="text-center">{t("register_logic.user_not_validated_2")}</h5>
                        // </>
                    }

                </TabPane>
            </TabContent>
        </div >
    )
}

export default UserPageComponent;