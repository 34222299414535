import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import WebIcon from '../../assets/landing/icon_web.svg';
import EarthIcon from '../../assets/landing/icon_hearth.svg';
import MoneyIcon from '../../assets/landing/icon_money.svg';
import RulesIcon from '../../assets/landing/icon_rules.svg';
import CalendarIcon from '../../assets/landing/icon_calendar.svg';
import SheetIcon from '../../assets/landing/icon_sheet.svg';
import CalculatorIcon from '../../assets/landing/icon_calculator.svg';
import ChatIcon from '../../assets/landing/icon_chat.svg';
import Ellipse from '../../assets/landing/ellipse.svg';
import Madame from '../../assets/landing/madame.svg';

import '../../styles/Pages/landingpage.scss';

const LandingPage = ({ title }) => {

    const { t } = useTranslation();

    document.title = title;

    return (
        <>
            <div id="headerImgWrapper">
                <div id="titleSection">
                    <div className="text-center titleSectionContent">
                        <h1 className="text-uppercase pl-5 pr-5">
                            {t("home.ready_to_start")}
                        </h1>
                        <h3 className="mt-3">
                            {t("home.become_today")}
                        </h3>
                        <Link to="/signUp">
                            <button className="kwanukobuttonbigger mt-4 be_your_boss">
                                {t('home.be_your_own_boss')}
                            </button>
                        </Link>
                        <h3 className="text-uppercase pt-5">
                            {t("landing.see_advantages")}
                        </h3>
                    </div>
                </div>
            </div>

            <div className="landingContent text-center">
                <div className="text-center titleSectionMobile">
                    <h1 className="text-uppercase mb-4">
                        {t("home.ready_to_start")}
                    </h1>
                    <h3 className="mb-4">
                        {t("home.become_today")}
                    </h3>
                    <Link to="/signUp">
                        <button className="kwanukobutton mt-4 mb-5 be_your_boss">
                            {t('home.be_your_own_boss')}
                        </button>
                    </Link>
                    <h3 className="text-uppercase">
                        {t("landing.see_advantages")}
                    </h3>
                </div>
                <div className="landingTable">
                    <Row noGutters={true} md="6" sm="1" xs="1">
                        <Col>
                            <div className="bullet-group">
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet remove-tablet" src={Ellipse} alt="bullet" />
                            </div>
                        </Col>
                        <Col>
                            <div className="iconWrapper">
                                <img className="icon" src={WebIcon} alt="web" />
                            </div>
                            <p className="mt-3">{t("landing.step1")}</p>
                        </Col>
                        <Col>
                            <div className="bullet-group">
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                            </div>
                        </Col>
                        <Col>
                            <div className="iconWrapper">
                                <img className="icon" src={MoneyIcon} alt="earth" />
                            </div>
                            <p className="mt-3">{t("landing.step3")}</p>
                        </Col>
                        <Col>
                            <div className="bullet-group">
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                            </div>
                        </Col>
                        <Col>
                            <div className="iconWrapper">
                                <img className="icon" src={EarthIcon} alt="money" />
                            </div>
                            <p className="mt-3">{t("landing.step2")}</p>
                        </Col>
                    </Row>
                    <Row noGutters={true} md="6" sm="1" xs="1">
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col className="vertical-bullet-group">
                            <img className="vertical-bullet mb-3 mt-4" src={Ellipse} alt="bullet" />
                            <img className="vertical-bullet mb-4 mt-3" src={Ellipse} alt="bullet" />
                        </Col>
                    </Row>
                    <Row noGutters={true} md="6" sm="1" xs="1">
                        <Col>
                        </Col>
                        <Col className="coming_soon">
                            <div className="iconWrapper">
                                <img className="icon" src={SheetIcon} alt="web" />
                                <p className="coming_soon_text">
                                    {t('landing.coming_soon')}
                                </p>
                            </div>
                            <p className="mt-3">{t("landing.step6")}</p>

                        </Col>
                        <Col>
                            <div className="bullet-group">
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                            </div>
                        </Col>
                        <Col>
                            <div className="iconWrapper">
                                <img className="icon" src={CalendarIcon} alt="earth" />
                            </div>
                            <p className="mt-3">{t("landing.step5")}</p>
                        </Col>
                        <Col>
                            <div className="bullet-group">
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                            </div>
                        </Col>
                        <Col>
                            <div className="iconWrapper">
                                <img className="icon" src={RulesIcon} alt="money" />
                            </div>
                            <p className="mt-3">{t("landing.step4")}</p>
                        </Col>
                    </Row>
                    <Row noGutters={true} md="6" sm="1" xs="1">
                        <Col>
                        </Col>
                        <Col className="vertical-bullet-group">
                            <img className="vertical-bullet mt-4 mb-3" src={Ellipse} alt="bullet" />
                            <img className="vertical-bullet mb-4 mt-3" src={Ellipse} alt="bullet" />
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row noGutters={true} md="6" sm="1" xs="1">
                        <Col>
                        </Col>
                        <Col className="coming_soon">
                            <div className="iconWrapper">
                                <img className="icon" src={CalculatorIcon} alt="web" />
                                <p className="coming_soon_text">
                                    {t('landing.coming_soon')}
                                </p>
                            </div>
                            <p className="mt-3">{t("landing.step7")}</p>

                        </Col>
                        <Col>
                            <div className="bullet-group">
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                                <img className="bullet" src={Ellipse} alt="bullet" />
                            </div>
                        </Col>
                        <Col className="coming_soon">
                            <div className="iconWrapper">
                                <img className="icon" src={ChatIcon} alt="earth" />
                                <p className="coming_soon_text">
                                    {t('landing.coming_soon')}
                                </p>
                            </div>
                            <p className="mt-3">{t("landing.step8")}</p>

                        </Col>
                        <Col>
                        </Col>
                        <Col className="final-text-col">
                            <h3 className="text-uppercase final-text">{t("landing.and_more")}</h3>
                        </Col>
                    </Row>
                </div>
            </div>

            <div id="preFooter" className="lightgrey text-center">
                <h4 className="mb-0">
                    {t("landing.pre_footer")}
                </h4>

                <Link to="/signup"><button className="kwanukobuttonbigger text-uppercase mb-0">{t("landing.inscription")}</button></Link>

                <img className="madameIcon" src={Madame} alt="madame" />

            </div>
        </>
    )
}

export default LandingPage;