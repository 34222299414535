import {
    RECEIVE_OFFER_DATA,
    REQUEST_OFFER_DATA,
    RECEIVE_MULTIPLE_OFFER_DATA,
    RECEIVE_USER_OFFER_DATA,
    RECEIVE_COUNT_DATA,
    RECEIVE_PAGINATION_DATA,
    RECEIVE_SEARCH_DATA,
    RECEIVE_GEO_DATA,
    RECEIVE_SEARCHED_BY_DATA,
    DID_RECEIVE_DATA
} from '../actions/offerAction';

import Store from '../store/offerStore';

export const initialState = Store;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_OFFER_DATA:
            return {
                ...state,
                isFetching: true
            };
        case RECEIVE_OFFER_DATA:
            return {
                ...state,
                isFetching: false,
                offer: action.payload
            };
        case RECEIVE_MULTIPLE_OFFER_DATA:
            return {
                ...state,
                isFetching: false,
                offers: action.payload
            };
        case RECEIVE_USER_OFFER_DATA:
            return {
                ...state,
                isFetching: false,
                userOffers: action.payload
            }
        case RECEIVE_COUNT_DATA:
            return {
                ...state,
                isFetching: false,
                offerCount: action.payload
            }
        case RECEIVE_PAGINATION_DATA:
            return {
                ...state,
                isFetching: false,
                currentPage: action.payload
            }
        case RECEIVE_SEARCHED_BY_DATA:
            return {
                ...state,
                isFetching: false,
                searchedBy: action.payload
            }
        case RECEIVE_SEARCH_DATA:
            return {
                ...state,
                isFetching: false,
                searchData: action.payload
            }
        case RECEIVE_GEO_DATA:
            return {
                ...state,
                isFetching: false,
                geoData: action.payload
            }
        case DID_RECEIVE_DATA:
            return {
                ...state,
                isFetching: false,
                foundOffers: action.payload
            };
        default:
            return state;
    }
}