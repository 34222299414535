import React from 'react';
import { Map, Marker, Circle } from 'google-maps-react';

const MapComponent = ({ offer, google, map, circle, handleMapIdle, displayAddress }) => {
    const center = { lat: parseFloat(offer.latitude), lng: parseFloat(offer.longitude) };

    return (
        <Map
            ref={map}
            className="map"
            google={google}
            containerStyle={{ width: '100%', height: '50vh', position: 'relative' }}
            style={{ position: "relative", width: "100%", height: '50vh' }}
            initialCenter={center}
            zoom={11}
            options
            mapTypeControl={false}
            zoomControl={true}
            streetViewControl={false}
            fullscreenControl={false}
            onIdle={handleMapIdle}
        >
            { displayAddress ? 
                <Marker
                    name={offer.title}
                    position={center}
                />
            :
                null 
            }

            <Circle
                ref={circle}
                radius={offer.distance * 1000}
                center={center}
                strokeColor='transparent'
                strokeOpacity={0}
                strokeWeight={5}
                fillColor='#FF0000'
                fillOpacity={0.2}
            />

        </Map>
    )
}

export default MapComponent;