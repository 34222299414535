import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paginationFilterSearch, 
    paginationWordSearch,
    paginationHomeSearch,
    receivePaginationData } 
from '../../actions/offerAction';

class Pagination extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    elmnt = document.getElementById('offerWrapper');

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 10);
        
        this.props.receivePaginationData(selected);
        if (this.props.searchedBy === "Category") {
            this.props.paginationFilterSearch(offset);
        } else if (this.props.searchedBy === "Keywords/Postal") {
            this.props.paginationWordSearch(offset);
        } else if (this.props.searchedBy === "home") {
            this.props.paginationHomeSearch(offset);
        }

        this.elmnt.scrollIntoView({
            //behavior: 'smooth'
        });
    };

    render() {
        const { Layout } = this.props;
        
        return (
            <Layout 
                handlePageClick={this.handlePageClick.bind(this)}
                pageCount={Math.ceil(this.props.offerCount / 10)}
                pageNumber={this.props.currentPage}
                offers={this.props.offers}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
    offers: state.offer.offers,
    offerCount: state.offer.offerCount,
    currentPage: state.offer.currentPage,
    searchedBy: state.offer.searchedBy
});

const mapDispatchToProps = {
    paginationFilterSearch,
    paginationWordSearch,
    paginationHomeSearch,
    receivePaginationData
};


export default connect(mapStateToProps, mapDispatchToProps)(Pagination);