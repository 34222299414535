import React from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import '../../styles/Pages/passwordReset.scss';
import Loading from '../UI/Loading';


const PasswordReset = ({
    checkPasswordConfirmation,
    handleChangeInput,
    handleResetPassword,
    title,
    state,
    loading_reset,
    reset_success,
    reset_error }) => {

    document.title = title;
    const { t } = useTranslation();

    return (
        <div id="passwordResetContainer">
            <div id="formWrapper">
                <div className="signUpTitle" style={{ textAlign: "center" }}>
                    <img className="logo" src={require("../../assets/logo/kwanuko_dragon.png")} alt="dragon logo" />
                    <h4 className="mt-3">{t('password_reset.title')}</h4>
                    <hr />
                </div>
                <div>
                    <span className="expired_token">
                        {
                            (state &&
                                state.formErrors &&
                                state.formErrors.reset &&
                                state.formErrors.reset === 'error.token.expired')
                                ||
                                (state && !state.reset_token)
                                ?
                                t("password_reset.expired_token") : undefined}
                    </span>
                </div>
                <Form className="resetForm">
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="labelWithText" htmlFor="email">{t('password_reset.new_password')}</Label>
                                <Input
                                    className={`${state && state.password ? 'fullinput' : ''}`}
                                    type="password" name="password" placeholder={t("password_reset.new_password_placeholder")} autoComplete="username" onChange={e => handleChangeInput(e)}></Input>
                                <span className="errormessagesmall">
                                    {
                                        state &&
                                            state.formErrors.reset &&
                                            state.formErrors.reset.password
                                            ?
                                            t(state.formErrors.reset.password) : undefined}
                                </span>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="labelWithText" htmlFor="passwordConfirmation">{t('password_reset.new_password_confirmation')}</Label>
                                <Input
                                    className={`${state && state.passwordConfirmation ? 'fullinput' : ''}`}
                                    type="password"
                                    name="passwordConfirmation"
                                    placeholder={t("password_reset.new_password_confirmation_placeholder")}
                                    autoComplete="current-password"
                                    onChange={e => handleChangeInput(e)}
                                    onBlur={checkPasswordConfirmation}></Input>
                                <span className="errormessagesmall">
                                    {
                                        state &&
                                            state.formErrors.reset &&
                                            state.formErrors.reset.passwordConfirmation
                                            ?
                                            t(state.formErrors.reset.passwordConfirmation) : undefined}
                                </span>
                                <span className="errormessagesmall">
                                    {
                                        state &&
                                            !state.check_confirmation
                                            ?
                                            t('forgot.not_similar') : undefined}
                                </span>
                            </Col>
                        </Row>
                    </FormGroup>

                    <div className="text-center mt-3">
                        {
                            reset_success ?
                                <p className="confirmationmessagesmall">{t('forgot.success_reset')}</p>
                                :
                                <button
                                    type="submit"
                                    onClick={(e) => { handleResetPassword(e) }}
                                    className={`kwanukobutton ${loading_reset ? 'loading' : undefined}`}>
                                    {
                                        loading_reset ?
                                            <Loading button={true} />
                                            :
                                            t("password_reset.submit")
                                    }
                                </button>
                        }

                    </div>
                </Form>
            </div>
        </div>
    )
}

export default PasswordReset;