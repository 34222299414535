export const checkFields = (fields, data) => new Promise((resolve, reject) => {

    let errors = [];
    var containsat = /[@]/;
    var containspoint = /[.]/;
    var hasNumber = /\d/;
    var hasLowercase = /[a-z]/;
    var hasUppercase = /[A-Z]/;
    var hasSpecialCharacter = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    for (let i in fields) {
        for (let j in fields[i]) {
            if (j === 'empty' && (data[i] === '' || !data[i])) {
                errors[i] = fields[i].empty;
                break;
            }
            if (j === 'isEmail' && (!containsat.test(data[i]) || !containspoint.test(data[i]))) {
                errors[i] = fields[i].isEmail;
                break;
            }
            if (j === 'isPhone' && (data[i].length < 10)) {
                errors[i] = fields[i].isPhone;
                break;
            }
            if (j === 'format'
                && (data[i].length < 8
                    || data[i].length > 20
                    || !hasNumber.test(data[i])
                    || !hasLowercase.test(data[i])
                    || !hasUppercase.test(data[i])
                    || !hasSpecialCharacter.test(data[i]))) {
                errors[i] = fields[i].format;
                break;
            }
        }
    }
    if (Object.keys(errors).length > 0) {
        console.log(errors);
        return reject(errors);
    }
    resolve();
});

export const checkAdFields = (fields, data) => new Promise((resolve, reject) => {
    let errors = [];

    for (let i in fields) {
        for (let j in fields[i]) {
            if (j === 'empty' && (data[i] === '' || !data[i])) {
                errors[i] = fields[i].empty;
                break;
            }
        }
    }
    if (Object.keys(errors).length > 0) {
        return reject(errors);
    }
    resolve();
})