import {
    RECEIVE_CATEGORY_DATA,
    REQUEST_CATEGORY_DATA,
    RECEIVE_MULTIPLE_CATEGORY_DATA
} from '../actions/categoryAction';

import Store from '../store/categoryStore';

export const initialState = Store;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CATEGORY_DATA:
            return {
                ...state,
                isFetching: true
            };
        case RECEIVE_CATEGORY_DATA:
            return {
                ...state,
                isFetching: false,
                category: action.payload
            };
        case RECEIVE_MULTIPLE_CATEGORY_DATA:
            return {
                ...state,
                isFetching: false,
                categories: action.payload
            };
        default:
            return state;
    }
}