export default {
    isFetching: false,
    isRequesting: false,
    user: {
        token: null
    },
    card: {},
    bannerImage: undefined,
    otherImages: [],
    interceptorId: undefined
};