import React from "react";
import { useDropzone } from "react-dropzone";
//import { useTranslation } from 'react-i18next';

const Dropzone = ({ onDrop, accept, label, multiple, maxFiles, image }) => {
    //const { t } = useTranslation();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
        maxFiles,
        multiple
    });

    const overrideDropzoneStyle = {
        marginTop: "8px",
        marginBottom: "4px",
        padding: "15px",
        border: '2px solid',
        borderImageSource: 'linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%)',
        borderImageSlice: 1,
        outline: 'none !important'
    };

    const overrideDropzoneStyleEmpty = {
        marginTop: "8px",
        marginBottom: "4px",
        padding: "15px",
        border: "1px solid #ced4da",
        outline: 'none !important'
    };

    return (
        <div {...getRootProps()}>
            <input className="dropzone-input" {...getInputProps()} />
            <div className="text-center">
                {/* Maybe delete this conditional */}
                {isDragActive ? (
                    <p className="dropzone-content" style={overrideDropzoneStyle}>PUT UR BANNER IMAGE HERE</p>
                ) : (
                        <p className="dropzone-content" style={image !== undefined && image.length ? overrideDropzoneStyle : overrideDropzoneStyleEmpty}>
                            {label}
                        </p>
                    )}
            </div>
        </div>
    );
};

export default Dropzone;
