import React from 'react';
import { Modal, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import '../../styles/Pages/forgot.scss';
import Loading from '../UI/Loading';

const Forgot = ({
    handleforgotPassword,
    handleChangeInput,
    openForgot,
    isOpenForgot,
    loadingForgot,
    sendForgot,
    formErrors,
    errorForgot }) => {

    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpenForgot} toggle={openForgot}>
            <Form id="forgotContainer">
                <FormGroup>
                    <div className="modal_header">
                        <h4>{t("forgot.title")}</h4>
                        <hr />
                        <span>{t("forgot.description")}</span>
                    </div>

                    <Row className="mt-4 mb-4">
                        <Col>
                            <Label className="labelWithText" htmlFor="email">{t('register.email')}</Label>
                            <Input
                                className={`'fullinput' : ''}`}
                                type="email" name="email" placeholder={t("forgot.email")}
                                autoComplete="username" onChange={e => handleChangeInput(e)}></Input>
                            <span className="errormessagesmall">
                                {
                                    formErrors &&
                                        formErrors.forgot &&
                                        formErrors.forgot.email
                                        ?
                                        t(formErrors.forgot.email) : undefined
                                }
                            </span>
                        </Col>
                    </Row>
                </FormGroup>

                <div className="text-center">
                    {
                        errorForgot ?
                            <p className="errormessagesmall">{t('forgot.error_sending')}</p>
                            :
                            undefined
                    }
                    {
                        sendForgot ?
                            <p className="confirmationmessagesmall">{t('forgot.success_sending')}</p>
                            :
                            <button
                                type="submit"
                                onClick={e => { handleforgotPassword(e) }}
                                className={`kwanukobutton ${loadingForgot ? 'loading' : undefined}`}>
                                {
                                    loadingForgot ?
                                        <Loading button={true} />
                                        :
                                        t("forgot.reinitialize")}
                            </button>
                    }

                </div>
            </Form>
        </Modal>

    )
}

export default Forgot;
