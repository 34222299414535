import React, { Component } from 'react';
import { connect } from 'react-redux';

class Privacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: ""
        }
    }

    componentDidMount() {

    }

    render() {
        const { Layout, title } = this.props;
        return (
            <Layout
                title={title}
            />
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);