import axios from 'axios';
import AppConfig from '../config/config';

export const REQUEST_CATEGORY_DATA = 'REQUEST_CATEGORY_DATA';
export const RECEIVE_CATEGORY_DATA = 'RECEIVE_CATEGORY_DATA';
export const RECEIVE_MULTIPLE_CATEGORY_DATA = "RECEIVE_MULTIPLE_CATEGORY_DATA"

export const requestCategoryData = () => ({
    type: REQUEST_CATEGORY_DATA,
});

export const receiveCategoryData = (data) => ({
    type: RECEIVE_CATEGORY_DATA,
    payload: data,
});

export const receiveMultipleCategoryData = (data) => ({
    type: RECEIVE_MULTIPLE_CATEGORY_DATA,
    payload: data,
});

export const getAllCategories = () => (
    (dispatch) => new Promise((resolve, reject) => {
        dispatch(requestCategoryData());
        axios.get(AppConfig.baseUrlApi + '/category?sort=title%20ASC')
            .then(response => {
                dispatch(receiveMultipleCategoryData(response.data))
                resolve();
            }).catch((err) => {
                reject({ err });
            })
    })
);