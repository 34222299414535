import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenLogin: false
        }
    }

    toggleLogin = () => {
        this.setState({
            isOpenLogin: !this.state.isOpenLogin
        })
    }

    render() {
        const { Layout } = this.props;

        return (
            <Layout
                user={this.props.user}
                isOpenLogin={this.state.isOpenLogin}
                toggleLogin={this.toggleLogin.bind(this)}
                currentPath={this.props.location.pathname}
            />
        )
    }
}



const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));