import React from 'react';
import ReactPaginate from 'react-paginate';
import '../../styles/UI/pagination.scss';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Pagination = ({ pageCount, pageNumber, handlePageClick, offers }) => {
    if (offers && offers.length) {
        return (
            <div className="offerPagination">
                <ReactPaginate
                    previousLabel={<IoIosArrowBack size={'1em'} />}
                    nextLabel={<IoIosArrowForward size={'1em'} />}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    forcePage={pageNumber}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages'}
                    activeClassName={'active'}
                />
            </div>
        );
    } else {
        return null
    }
}

export default Pagination