import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatCVC, formatCreditCardNumber, formatExpirationDate } from "../../config/paymentUtils";

import { updateUser, isConnected, updatePassword, sendSponsorCode, createUserCard, updateUserCard, getUserData, chooseSubscription, cancelSubscription } from '../../actions/userAction';
import { getOffer, getUserOffers, removeOffer } from '../../actions/offerAction';
import { errorMessagesReset, errorMessagesSponsor } from '../../config/messages';
import { checkFields } from '../../config/utils';
import i18n from '../../translations/i18n';

class UserPage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isLoading: false,
            isUpdated: false,
            isDeleteOpen: false,
            isUnsubscribeOpen: false,
            activeTab: 1,
            offerToBeDeleted: undefined,
            user: { ...this.props.user.user },
            refuseText: undefined,
            refuseMessage: undefined,
            updatedBilling: undefined,

            currentCard: {
                cvc: '',
                number: this.props.user.user.billingCardLast4 ? "************" + this.props.user.user.billingCardLast4 : '',
                name: this.props.user.user.billingCardFirstname ? this.props.user.user.billingCardFirstname : '',
                expiry: this.props.user.user.billingCardExpYear ? this.props.user.user.billingCardExpMonth + '/' + this.props.user.user.billingCardExpYear : ''
            },
            formErrors: {},
            success_reset: false,
            loading_reset: false,
            password: undefined,
            passwordConfirmation: undefined,
            check_confirmation: true,
            sponsorEmail: undefined,
            sponsorName: undefined
        }
    }

    componentDidMount() {
        this.props.isConnected().then(() => {
            this.props.getUserData().then(() => {
                this.setState({
                    user: { ...this.props.user.user },
                })
            })
            this.props.getUserOffers(this.props.user.user);
        }).catch(() => {
            this.props.history.push("/login");
        })
    }

    toggleTab = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    handleUpdateInput = e => {
        this.setState({ user: { ...this.state.user, [e.target.name]: e.target.value } });
    }

    handlePhoneInput = (phone) => {
        this.setState({ user: { ...this.state.user, phone: '+' + phone } });
    }

    handleChangeInput = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    checkPasswordConfirmation = () => {
        this.setState({
            check_confirmation: true,
            formErrors: {}
        })
        if (this.state.password && this.state.passwordConfirmation && this.state.password !== this.state.passwordConfirmation) {
            this.setState({
                check_confirmation: false
            })
        }
    }

    handleUpdateUser = e => {
        if (e) e.preventDefault();
        this.setState({
            ...this.state,
            isUpdating: true,
            isUpdated: false,
            formErrors: {}
        });
        this.props.updateUser(this.state.user)
            .then(() => {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    isUpdated: true,
                })
            })
            .catch(err => {
                let errors = {
                    existing_user: err.response.data.message === "error.account.update.profile.emailAlreadyInUse" ? i18n.t("register_logic.email_already_exist") : undefined,
                };
                this.setState(state => ({
                    ...this.state,
                    isUpdating: false,
                    isUpdated: false,
                    formErrors: {
                        ...state.formErrors,
                        update: errors
                    },
                }))
            })
    }

    handleUpdateCurrentCard = e => {
        if (e.target.name === "number") e.target.value = formatCreditCardNumber(e.target.value);
        if (e.target.name === "expiry") e.target.value = formatExpirationDate(e.target.value);
        if (e.target.name === "cvc") e.target.value = formatCVC(e.target.value);


        this.setState({ currentCard: { ...this.state.currentCard, [e.target.name]: e.target.value } });

    }

    handleSubscribe = e => {
        if (e) e.preventDefault();
        this.setState({
            updating_billing: true,
            updatedBilling: undefined
        })
        this.props.createUserCard(this.state.currentCard)
            .then(_ => {
                this.setState({
                    ...this.state,
                    confirmedBilling: true,
                    updating_billing: false,
                    isActive: true
                })
            })
            .catch(err => {
                this.setState({
                    updating_billing: false,
                    updatedBilling: false,
                    isActive: false
                })
            })
    }

    handleUpdateBilling = e => {
        if (e) e.preventDefault();
        this.setState({
            updating_billing: true,
            updatedBilling: undefined
        })
        this.props.updateUserCard(this.state.currentCard)
            .then(_ => {
                this.setState({
                    ...this.state,
                    updatedBilling: true,
                    updating_billing: false
                })
            })
            .catch(err => {
                this.setState({
                    updating_billing: false,
                    updatedBilling: false,
                })
            });
    }

    handleFocusField = e => {
        this.setState({ focusedField: e.target.name });
    }

    handleEditAdClick = (id) => {
        this.props.getOffer(id);
    }

    toggleDelete = (id) => {
        if (this.state.offerToBeDeleted === undefined) {
            this.setState({
                ...this.state,
                isDeleteOpen: !this.state.isDeleteOpen,
                offerToBeDeleted: id
            });
        } else {
            this.setState({
                ...this.state,
                isDeleteOpen: !this.state.isDeleteOpen,
                offerToBeDeleted: undefined
            });
        }
    }

    deleteAd = (offerId) => {
        this.props.removeOffer(offerId)
            .then(() => {
                this.toggleDelete();
                window.location.reload();
            })
            .catch(err => console.log("ERROR -> e.js -> deletedAd -> removeOffer", err))
    }

    handleResetPassword = (e) => {
        e.preventDefault();
        this.setState({
            loading_reset: true,
            formErrors: {},
            reset_success: undefined,
            reset_error: undefined
        })
        checkFields(errorMessagesReset, this.state).then(() => {
            if (this.state.password === this.state.passwordConfirmation) {
                this.setState({
                    check_confirmation: true,
                })
                this.props.updatePassword(this.state.password).then(res => {
                    this.setState({
                        success_reset: true,
                        loading_reset: false,
                        formErrors: {},
                        reset_success: true
                    })
                }).catch(err => {
                    this.setState(state => ({
                        success_reset: false,
                        loading_reset: false,
                        formErrors: {
                            ...state.formErrors,
                            reset: err.message.response.data.message
                        },
                        reset_error: true
                    }))
                })
            } else {
                this.setState({
                    loading_reset: false,
                    success_reset: false
                })
            }
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    reset: errors,
                },
                loading_reset: false,
                success_reset: false
            }))
        })
    }

    handleSendSponsor = (e) => {
        e.preventDefault();
        this.setState({
            loading_sponsor: true,
            formErrors: {},
            success_sponsor: undefined,
            sponsor_error: undefined
        })
        checkFields(errorMessagesSponsor, this.state).then(() => {
            this.props.sendSponsorCode(this.state.sponsorName, this.state.sponsorEmail, this.state.user.lastname, this.state.user.firstname, this.state.user.sponsorCode).then(res => {
                this.setState({
                    success_sponsor: true,
                    loading_sponsor: false,
                    formErrors: {},
                })
            }).catch(err => {
                this.setState(state => ({
                    success_sponsor: false,
                    loading_sponsor: false,
                    formErrors: {
                        ...state.formErrors,
                        sponsor: err.message.response.data.message
                    },
                    sponsor_error: true
                }))
            })
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    sponsor: errors
                },
                loading_sponsor: false,
            }))
        })
    }

    toggleRefuse = e => {
        if (e.target.value === "refuse") {
            this.setState({
                ...this.state,
                refuseText: true,
                subscriptionOption: e.target.value
            })
        } else {
            this.setState({
                ...this.state,
                refuseText: false,
                subscriptionOption: e.target.value
            })
        }
    }

    handleSubscriptionChange = e => {
        if (e.target.name === "reason")
            this.setState({
                ...this.state,
                refuseMessage: e.target.value
            });
    }

    handleConfirmSubscription = () => {
        this.setState({
            isSuscribing: true,
        })
        let decision = undefined
        if (this.state.subscriptionOption === "refuse")
            decision = false;
        else decision = true;

        this.props.chooseSubscription(decision, this.state).then(() => {
            this.setState({
                isSuscribing: false
            })
            this.props.getUserData().then(() => {
                this.setState({
                    user: { ...this.props.user.user }
                })
            })
        }).catch(() => {
            this.setState({
                isSuscribing: false
            })
        });
    }

    toggleUnsubscribe = () => {
        this.setState({
            isUnsubscribeOpen: !this.state.isUnsubscribeOpen,
        });
    }

    handleCancelSubscription = () => {
        this.props.cancelSubscription();
    }

    handlePaymentType = (e) => {
        this.setState({
            paymentType: e.target.value
        })
    }

    render() {
        const { Layout, title } = this.props;

        return (
            <Layout
                state={this.state}
                user={this.state.user}
                handleUpdateInput={this.handleUpdateInput.bind(this)}
                handleUpdateUser={this.handleUpdateUser.bind(this)}
                handleChangeInput={this.handleChangeInput.bind(this)}
                checkPasswordConfirmation={this.checkPasswordConfirmation.bind(this)}
                title={title}
                toggleTab={this.toggleTab.bind(this)}
                activeTab={this.state.activeTab}
                isLoading={this.state.isLoading}
                isUpdated={this.state.isUpdated}
                isUpdating={this.state.isUpdating}

                isAccepted={!(this.props.user.user.status === "inactive")}
                isValidated={(this.props.user.user.status === "subscription_send"
                    || this.props.user.user.status === "subscription_refuse"
                    || this.props.user.user.status === "payment_pending"
                    || this.props.user.user.status === "active")}
                acceptedSubscription={(this.props.user.user.status === "payment_pending"
                    || this.props.user.user.status === "active")}
                refusedSubscription={this.props.user.user.status === "subscription_refuse"}
                isActive={this.props.user.user.status === "active"}
                toggleRefuse={this.toggleRefuse.bind(this)}
                handleSubscriptionChange={this.handleSubscriptionChange.bind(this)}
                handleConfirmSubscription={this.handleConfirmSubscription.bind(this)}
                refuseText={this.state.refuseText}
                refuseMessage={this.state.refuseMessage}
                subscriptionOption={this.state.subscriptionOption}
                handleUpdateBilling={this.handleUpdateBilling.bind(this)}
                updatedBilling={this.state.updatedBilling}
                subExpDate={this.props.user.user.subscriptionExpiresAt}

                currentCard={this.state.currentCard}
                focusedField={this.state.focusedField}
                handleUpdateCurrentCard={this.handleUpdateCurrentCard.bind(this)}
                handleFocusField={this.handleFocusField.bind(this)}
                handleSubscribe={this.handleSubscribe.bind(this)}
                handleCancelSubscription={this.handleCancelSubscription.bind(this)}
                toggleUnsubscribe={this.toggleUnsubscribe.bind(this)}
                unsubscribeModal={this.state.isUnsubscribeOpen}

                offers={this.props.userOffers}
                handleEditAdClick={this.handleEditAdClick.bind(this)}
                toggleDelete={this.toggleDelete.bind(this)}
                deleteModal={this.state.isDeleteOpen}
                deleteAd={this.deleteAd.bind(this)}
                offerToBeDeleted={this.state.offerToBeDeleted}

                reset_success={this.state.reset_success}
                handleResetPassword={this.handleResetPassword.bind(this)}
                loading_reset={this.state.loading_reset}

                handleSendSponsor={this.handleSendSponsor.bind(this)}
                handlePhoneInput={this.handlePhoneInput.bind(this)}
                handlePaymentType={this.handlePaymentType.bind(this)}
                paymentType={this.state.paymentType}
                emailSaved={this.props.user.user.emailAddress}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userOffers: state.offer.userOffers
})

const mapDispatchToProps = {
    updateUser,
    getOffer,
    getUserOffers,
    removeOffer,
    isConnected,
    updatePassword,
    sendSponsorCode,
    createUserCard,
    updateUserCard,
    getUserData,
    chooseSubscription,
    cancelSubscription
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);