import React from 'react';
import { Map, Marker, InfoWindow } from 'google-maps-react';
import { Link } from "react-router-dom";
import { Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import '../../styles/Pages/home.scss'
import Loading from '../UI/Loading';
import PaginationContainer from '../../containers/UI/Pagination';
import PaginationComponent from '../../components/UI/Pagination';
import { useTranslation } from 'react-i18next';
import { AiFillPicture } from 'react-icons/ai';

const MapListComponent = ({ initialCenter,
    google,
    center,
    clicked,
    onMarkerClick,
    zoom,
    closed,
    visible,
    selectedPlace,
    offers,
    selectedAd,
    currentAd,
    isLoading,
    foundOffers,
    searchedBy,
    searchData }) => {

    const { t, i18n } = useTranslation();
    let color = "";

    let searched = (searchedBy === "Category") ? t("my_account.category") : (searchedBy === "Keywords/Postal") ? t("home.keywords_postal") : "home";
    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <Col className="mapcol">
                <Map
                    className="map"
                    google={google}
                    containerStyle={{ width: '100%', height: 'calc(95vh - 80px)', position: 'relative' }}
                    style={{ position: "relative", width: "100%", height: 'calc(95vh - 80px)' }}
                    initialCenter={initialCenter}
                    center={center}
                    onClick={clicked}
                    zoom={zoom}
                    mapTypeControl={false}
                    zoomControl={true}
                    streetViewControl={false}
                    fullscreenControl={false}>

                    {offers && offers?.length && offers.map((offer, index) => {

                        switch (offer && offer.category && offer.category.id) {
                            case 1:
                                color = "Darkgreen";
                                break;
                            case 2:
                                color = "Darkseagreen";
                                break;
                            case 3:
                                color = "Dumpster";
                                break;
                            case 4:
                                color = "Forestgreen";
                                break;
                            case 5:
                                color = "Freshgreen";
                                break;
                            case 6:
                                color = "Green2";
                                break;
                            case 7:
                                color = "Green4";
                                break;
                            case 8:
                                color = "Greentaxi";
                                break;
                            case 9:
                                color = "Gummigreen";
                                break;
                            case 10:
                                color = "Lightgreen";
                                break;
                            case 11:
                                color = "Mediumseagreen";
                                break;
                            case 12:
                                color = "Mintgreen";
                                break;
                            case 13:
                                color = "Offwhitegreen";
                                break;
                            case 14:
                                color = "Seagreen";
                                break;
                            case 15:
                                color = "Seagreen1";
                                break;
                            case 16:
                                color = "Sgichartreuse";
                                break;
                            case 17:
                                color = "Springgreen";
                                break;
                            case 18:
                                color = "Springgreen4";
                                break;
                            case 19:
                                color = "Viridianlight";
                                break;
                            case 20:
                                color = "Wasabi";
                                break;
                            default:
                                color = "Darkgreen";
                        }

                        let iw = 80, ih = 80;

                        return <Marker
                            title={offer.title}
                            englishTitle={offer.englishTitle}
                            uniqueTitle={offer.uniqueTitle}
                            banner={offer.bannerImage}
                            category={offer && offer.category && offer.category.title}
                            price={offer.price}
                            offerId={offer.id}
                            position={{ lat: offer.latitude, lng: offer.longitude }}
                            onClick={onMarkerClick}
                            key={index}
                            icon={{
                                url: require(`../../assets/markers/${color}.png`),
                                anchor: new google.maps.Point(iw / 4, ih / 2 - 5),
                                size: new google.maps.Size(iw / 2, ih / 2),
                                scaledSize: new google.maps.Size(iw / 2, ih / 2),
                            }}
                        />
                    })}

                    <InfoWindow
                        position={center}
                        onClose={closed}
                        maxWidth={400}
                        maxHeight={200}
                        visible={visible}>
                        <div>
                            <img src={selectedPlace.banner} className="infoWindowImg" alt="banner" />
                            <p className="text-secondary mt-2 categoryMapList">{selectedPlace.category}</p>
                            <span style={{ display: "inline-block" }}>
                                <span style={{ float: "left" }} className={`dot-${color}`} ></span>
                                <span style={{ float: "right" }}><h5>{i18n.language === 'fr' ? selectedPlace.title : selectedPlace.englishTitle}</h5></span>
                            </span>
                            <h6>{selectedPlace?.price?.toFixed(2)} {t('offer_infos.dolls_hour')}</h6>
                            <a href={`/pages/${selectedPlace.uniqueTitle}`}><button className="category text-uppercase kwanukobutton">{t("offer_details.consult")}</button></a>
                        </div>
                    </InfoWindow>
                </Map>
            </Col>

            <div className="offers">
                <ListGroup>
                    {(searched !== "home" && searchData.length >= 1) ?
                        <p>
                            <span className="bold">{t("global.search")} </span><span>{">>"} {searched} {">>"} </span>
                            { searchData[0] === "category" ? 
                                <span>{i18n.language === 'fr' ? searchData[1] : searchData[2]}</span>
                                :
                                searchData.map((el, i) => {
                                    if (i === 0) return null
                                    else return <span key={i}>{el} </span>
                                })
                            }
                        </p>
                        :
                        undefined
                    }
                    {foundOffers ?
                        undefined
                        :
                        <div className="m-auto no_offer_container">
                            <p className="text-center mt-5 bold no_offers">{t('home.no_offers_found')}</p>
                        </div>
                    }
                    {
                        offers && offers.length ?
                            offers.map((offer, index) => {
                                let descExcerpt = "";
                                let distance;
                                if (i18n.language === 'fr') {
                                    if (offer.description && offer.description.replace(/(<([^>]+)>)/gi, "").replace(/(&([^;]+);)/gi, "").trim().length > 150) {
                                        descExcerpt = offer.description.replace(/(<([^>]+)>)/gi, "").replace(/&#39;/g, "'").trim().slice(0, 150) + "..."
                                    }
                                } else {
                                    if (offer.englishDescription && offer.englishDescription.replace(/(<([^>]+)>)/gi, "").replace(/(&([^;]+);)/gi, "").trim().length > 150) {
                                        descExcerpt = offer.englishDescription.replace(/(<([^>]+)>)/gi, "").replace(/&#39;/g, "'").trim().slice(0, 150) + "..."
                                    }
                                }


                                if (offer.distanceFrom) {
                                    distance = Math.floor(offer.distanceFrom);
                                    if (distance === 0) {
                                        distance = "< 1";
                                    }
                                }


                                switch (offer && offer.category && offer.category.id) {
                                    case 1:
                                        color = "Darkgreen";
                                        break;
                                    case 2:
                                        color = "Darkseagreen";
                                        break;
                                    case 3:
                                        color = "Dumpster";
                                        break;
                                    case 4:
                                        color = "Forestgreen";
                                        break;
                                    case 5:
                                        color = "Freshgreen";
                                        break;
                                    case 6:
                                        color = "Green2";
                                        break;
                                    case 7:
                                        color = "Green4";
                                        break;
                                    case 8:
                                        color = "Greentaxi";
                                        break;
                                    case 9:
                                        color = "Gummigreen";
                                        break;
                                    case 10:
                                        color = "Lightgreen";
                                        break;
                                    case 11:
                                        color = "Mediumseagreen";
                                        break;
                                    case 12:
                                        color = "Mintgreen";
                                        break;
                                    case 13:
                                        color = "Offwhitegreen";
                                        break;
                                    case 14:
                                        color = "Seagreen";
                                        break;
                                    case 15:
                                        color = "Seagreen1";
                                        break;
                                    case 16:
                                        color = "Sgichartreuse";
                                        break;
                                    case 17:
                                        color = "Springgreen";
                                        break;
                                    case 18:
                                        color = "Springgreen4";
                                        break;
                                    case 19:
                                        color = "Viridianlight";
                                        break;
                                    case 20:
                                        color = "Wasabi";
                                        break;
                                    default:
                                        color = "Darkgreen";
                                }

                                return (
                                    <ListGroupItem
                                        key={index}
                                        id={"id" + offer.id}
                                        onClick={() => selectedAd(offer)}
                                        className={offer.id === currentAd ? "active2" : index === offers.length - 1 ? "mb-0" : null}>
                                        <Row>
                                            <Col sm={4} className="offer_image">
                                                {
                                                    offer && offer.bannerImage ?
                                                        <img className="listBanner" src={offer.bannerImage} alt="banner" />
                                                        :
                                                        <div className="listBannerEmpty" >
                                                            <AiFillPicture size={'5em'} />
                                                        </div>
                                                }
                                            </Col>
                                            <Col className="col pt-2 pb-2 pl-0 offer_infos">
                                                <div className="offerInfos">
                                                    <ListGroupItemText className="text-secondary mb-0 categoryMapList">{offer && offer.category && offer.category.title}</ListGroupItemText>
                                                    <span style={{ display: "flex" }}>
                                                        <div>
                                                            <span style={{ float: "left" }} className={`dot ${color}`}></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ float: "right" }}>
                                                                <ListGroupItemHeading>{i18n.language === 'fr' ? offer.title : offer.englishTitle}</ListGroupItemHeading>
                                                            </span>
                                                        </div>
                                                    </span>
                                                    <ListGroupItemText className="text-secondary mb-0 text-left w-100">{offer && offer.distanceFrom ? `${distance} km ${t('home.from_your_search')}` : undefined}</ListGroupItemText>
                                                </div>

                                                {/** todo: TEST THIS */}
                                                <ListGroupItemText className="mt-2 mb-2">
                                                    {(descExcerpt === "") ? 
                                                        (i18n.language === 'fr' ?
                                                            (offer.description ?
                                                                offer.description.replace(/(<([^>]+)>)/gi, "").replace(/&#39;/g, "'").trim()
                                                                : 
                                                                "Aucune description n'a été fournie en français."
                                                            )
                                                            : 
                                                            (offer.englishDescription ? 
                                                                offer.englishDescription.replace(/(<([^>]+)>)/gi, "").replace(/&#39;/g, "'").trim()
                                                                :
                                                                "No description has been provided in english."
                                                            )
                                                        ) 
                                                    : 
                                                    descExcerpt}
                                                </ListGroupItemText>

                                                <div className="final_row">
                                                    <ListGroupItemText className="semiBold mb-0">
                                                        <span className="bold">{offer.price.toFixed(2)} {t('offer_infos.dolls_hour')}</span>
                                                    </ListGroupItemText>
                                                    <Link to={`/pages/${offer.uniqueTitle}`}><button className="category text-uppercase kwanukobutton">{t("offer_details.consult")}</button></Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })
                            :
                            <div className="no_offer_container">
                                <p className="text-center mt-5 bold no_offers">{t('global.no_offers')}</p>
                            </div>
                    }
                </ListGroup>
                <PaginationContainer Layout={PaginationComponent} />
            </div>
        </>
    )
}

export default MapListComponent;