import React from 'react';
import { Link } from "react-router-dom";
import { Navbar, Row, Col } from 'reactstrap';
import '../../styles/Layout/footer.scss';
import FacebookIcon from '../../assets/footer/social-facebook.svg';
import InstagramIcon from '../../assets/footer/social-instagram.svg';
import { useTranslation } from 'react-i18next';

const FooterComponent = ({ user, isOpenLogin, toggleLogin, currentPath }) => {
    const { t } = useTranslation();

    return (
        <div id="footerWrapper" className={`
        ${currentPath === '/account' ||
                currentPath === '/signup' ||
                currentPath === '/login' ||
                currentPath === '/offercreate' ||
                currentPath.includes('passwordreset') ||
                currentPath.includes('offeredit') ||
                currentPath.includes('terms') ||
                currentPath.includes('privacy') ||
                currentPath.includes('signUp')
                ? "" : "lightgrey"}`}>
            <div className="footer">
                <div className="footerContent">
                    {!user.token && currentPath !== '/login' ?
                        <>
                            <Row>
                                <Col className="centered">
                                    <h2 className="text-uppercase">{t("footer.access_message")}</h2>
                                </Col>
                                <Col className="centered">
                                    <Link to='/login'>
                                        <button onClick={toggleLogin}
                                            className="kwanukobutton text-uppercase">{t("global.login")}</button>
                                    </Link>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                        </>
                    }
                </div>
                <img className="dragon" src={require("../../assets/logo/dragon.png")} alt="dragon" />

                <hr />

                <Navbar className="footerNav">
                    <div className="bold copyrights text-uppercase">{t("footer.copyrights")}</div>
                    <div className="footerRight">
                        {(!user.token && currentPath !== '/signup') ?
                            <>
                                <Link to="/signup" className="animatedLink">
                                    <p> {t("global.register_header")}</p>
                                </Link>
                            </>
                            :
                            <></>
                        }
                        <a href="mailto:contact@kwanuko.com" className="animatedLink"><p>{t("footer.contact")}</p></a>
                        <Link to="/terms" className="animatedLink"><p>{t("footer.terms")}</p></Link>
                        <Link to="/privacy" className="animatedLink"><p>{t("footer.confidentiality")}</p></Link>
                        <div className="logoSocialMedia">
                            <a href="https://www.facebook.com/kwanuko" target='_BLANK' rel="noopener noreferrer" className="animatedLink"><img src={FacebookIcon} alt="social facebook" /></a>
                            <a href="https://www.instagram.com/kwanuko/" target='_BLANK' rel="noopener noreferrer" className="animatedLink"><img src={InstagramIcon} alt="social instagram" /></a>
                        </div>
                    </div>
                </Navbar>
            </div>
        </div>
    )
}

export default FooterComponent;