import React from 'react';
import { Col, Row, Button, Form, FormText, FormGroup, Label, Input } from 'reactstrap';
import LocationSearchInput from '../../containers/Utils/LocationSearchInput';
import '../../styles/Pages/offerForm.scss';
import Loading from '../UI/Loading';
import { useTranslation } from 'react-i18next';

import { IconContext } from "react-icons";
import { FaTimes } from 'react-icons/fa';
import Dropzone from '../UI/DropZone';

import { Editor } from '@tinymce/tinymce-react';
import Config from '../../config/config';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
};

const thumbDelete = {
    position: 'absolute',
    width: 90,
    height: 100,
    display: 'block',
    textAlign: 'right',
    paddingRight: '5px',
    //zIndex: 1000
};

const Thumb = fieldProps => {
    const {
        imgSource,
        deleteImage
    } = fieldProps;

    return (
        <div style={thumb} key={`thumb${imgSource}`}>
            <div style={thumbDelete}>
                <IconContext.Provider value={{ color: "red", className: "global-class-name", style: { cursor: "pointer" } }} >
                    <FaTimes onClick={() => deleteImage(imgSource)} size={'1.5em'} />
                </IconContext.Provider>
            </div>

            <div style={{ ...thumbInner }}>
                <img src={imgSource} style={img} alt="uploaded" />
            </div>
        </div>
    )
};

const OfferEditComponent = ({
    offer,
    title,
    state,
    isLoadingPage,
    handleUpdateAdInput,
    handleEditorChange,
    handleEditAd,
    categories,
    formErrors,
    onDrop,
    deleteBanner,
    onDropMultiple,
    deleteImage,
    isLoadingUpload,
    isLoadingUploadOther,
    generateUniqueTitle }) => {

    document.title = title;
    state = state.offer;

    const { t, i18n } = useTranslation();

    if (isLoadingPage) {
        window.scrollTo(0, 0);
        return (
            <Loading fullContainer={true} />
        )
    }

    if (state) {
        return (
            <div id="offerFormContainer">
                <div id="formWrapper">
                    <div className="signUpTitle" style={{ textAlign: "center" }}>
                        <img className="logo" src={require("../../assets/logo/kwanuko_dragon.png")} alt="dragon logo" />
                        <h4 className="mt-3">{t('create_offer.title_edit')}</h4>

                        {formErrors && formErrors.register ? Object.keys(formErrors.register).map(t => formErrors.register[t] ? <p className="labelWithText errormessagesmall" key={`error${t}`}>{i18n.t(formErrors.register[t])}</p> : undefined) : undefined}

                        <hr />
                    </div>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="title">{`${t('create_offer.offer_title')} (${t('global.french')})`}</Label>
                                    <Input
                                        type="text" name="title" tabIndex={1} value={offer.title} onChange={e => handleUpdateAdInput(e)} placeholder={t('create_offer_placeholders.title')}
                                        className={`${offer && offer.title ? 'fullinput' : ''}`}></Input>
                                </Col>
                                <Col>
                                    <Label className="labelWithText" htmlFor="englishTitle">{`${t('create_offer.offer_title')} (${t('global.english')})`}</Label>
                                    <Input
                                        type="text" name="englishTitle" value={offer.englishTitle} onChange={e => handleUpdateAdInput(e)} onBlur={e => generateUniqueTitle(e)} placeholder={t('create_offer_placeholders.title')}
                                        className={`${offer && offer.englishTitle ? 'fullinput' : ''}`}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="uniqueTitle">{t('create_offer.offer_unique_title')}</Label>
                                    <Input
                                        type="text" name="uniqueTitle" value={offer.uniqueTitle} onChange={e => handleUpdateAdInput(e)} onBlur={e => generateUniqueTitle(e)} placeholder={t('create_offer_placeholders.unique_title')}
                                        className={`${offer && offer.uniqueTitle ? 'fullinput' : ''}`}
                                    ></Input>
                                    <FormText color="muted">{`${t('create_offer.offer_unique_title_description')}${offer.uniqueTitle ? offer.uniqueTitle : ""}`}</FormText>
                                    {/* <FormText color="muted">{`${t('create_offer.offer_unique_title_description2')}`}</FormText> */}
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="bannerImage">{t('create_offer.offer_banner_image')}</Label>
                                    <section name="bannerImage" className="bannerImageMain">
                                        <Dropzone
                                            accept={'image/*'}
                                            onDrop={onDrop}
                                            label={t('create_offer.image_drop')}
                                            multiple={false}
                                            maxFiles={1}
                                            image={offer.bannerImage}
                                        />

                                        <aside style={thumbsContainer}>
                                            {!(offer.bannerImage === undefined) ? <Thumb imgSource={offer.bannerImage} deleteImage={deleteBanner} /> : <></>}
                                            {isLoadingUpload ?
                                                <Loading />
                                                :
                                                undefined}
                                        </aside>
                                    </section>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="otherImages">{t('create_offer.offer_other_images')}</Label>
                                    <section name="otherImages" className="bannerImageSecond">
                                        <Dropzone
                                            accept={'image/*'}
                                            onDrop={onDropMultiple}
                                            label={t('create_offer.image_drop')}
                                            multiple={false}
                                            maxFiles={2}
                                            image={offer.otherImages}
                                        />

                                        <aside style={thumbsContainer}>
                                            {offer.otherImages ?
                                                offer.otherImages.map((fileUrl, index) => (
                                                    <Thumb key={index} imgSource={fileUrl} deleteImage={deleteImage} />
                                                )) : <></>}
                                            {isLoadingUploadOther ?
                                                <Loading />
                                                :
                                                undefined
                                            }
                                        </aside>
                                    </section>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="price">{t('create_offer.offer_price')} ($CAD)</Label>
                                    <Input
                                        type="number" name="price" value={offer.price} onChange={e => handleUpdateAdInput(e)} placeholder={t('create_offer_placeholders.price')}
                                        className={`${offer && offer.price ? 'fullinput' : ''}`}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="address">{t('create_offer.offer_address')}</Label>
                                    <LocationSearchInput
                                        displayLabel={false}
                                        name="address"
                                        value={offer.address}
                                        className={`${offer && offer.address ? 'fullinput' : ''}`}
                                        placeholder={t('create_offer_placeholders.address')}
                                        onSelect={e => {
                                            handleUpdateAdInput({ target: { name: "address", value: e.formatted_address } });
                                        }} />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup className="checkbox_cgu_address">
                            <label className="checkbox bounce mb-0">
                                <input type="checkbox" style={{ display: 'inline-block' }} name="displayAddress" id="displayAddress" defaultChecked={offer.displayAddress} onChange={e => handleUpdateAdInput(e)} />
                                <svg viewBox="0 0 21 21">
                                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                </svg>
                                <label className="ml-2 mb-0" htmlFor="displayAddress">{t('create_offer.offer_display_address')}</label>
                            </label>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="language">{t('create_offer.offer_language')}</Label>
                                    <Input
                                        type="text" name="language" value={offer.language} onChange={e => handleUpdateAdInput(e)} placeholder={t('create_offer_placeholders.language')}
                                        className={`${offer && offer.language ? 'fullinput' : ''}`}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText">{`${t('create_offer.offer_description')} (${t('global.french')})`}</Label>
                                    <Editor
                                        apiKey={Config.tinyAPIKey}
                                        initialValue={offer.description}
                                        init={{
                                            height: 400,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image',
                                                'charmap print preview anchor help',
                                                'searchreplace visualblocks code',
                                                'insertdatetime media table paste wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                        }}
                                        onChange={e => handleEditorChange(e, 'description')}
                                    />
                                </Col>
                                <Col>
                                    <Label className="labelWithText">{`${t('create_offer.offer_description')} (${t('global.english')})`}</Label>
                                    <Editor
                                        apiKey={Config.tinyAPIKey}
                                        initialValue={offer.englishDescription}
                                        init={{
                                            height: 400,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image',
                                                'charmap print preview anchor help',
                                                'searchreplace visualblocks code',
                                                'insertdatetime media table paste wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                        }}
                                        onChange={e => handleEditorChange(e, 'englishDescription')}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label htmlFor="category">{t('create_offer.offer_category')}</Label>
                                    <Input type="select" name="category"
                                        onChange={e => handleUpdateAdInput(e)}
                                        placeholder={t('create_offer_placeholders.category')}
                                        className={`${offer && offer.category ? 'fullinput' : ''}`}
                                    >
                                        <option disabled>{t("create_offer.select_category")}</option>
                                        {categories.map((category, index) => {
                                            return <option selected={offer?.category === category.id} key={index} value={category.id}>{i18n.language === 'fr' ? category.title : category.englishTitle}</option>
                                        })}
                                        <option value="other" selected={offer?.category === 'other'}>{t("create_offer.other")}</option>
                                    </Input>
                                </Col>
                            </Row>
                        </FormGroup>


                        {
                            offer && offer.category === 'other' ?
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <Label className="labelWithText" htmlFor="newCategory">{`${t('create_offer.other_precise')}`}</Label>
                                            <Input
                                                type="text" name="newCategory" value={offer.newCategory} onChange={e => handleUpdateAdInput(e)} placeholder={t('create_offer.other_category')}
                                                className={`${offer && offer.newCategory ? 'fullinput' : ''}`}></Input>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                :
                                undefined
                        }
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="distance">{t('create_offer.offer_distance')}</Label>
                                    <Input type="number" name="distance" value={offer.distance} onChange={e => handleUpdateAdInput(e)} placeholder={t('create_offer_placeholders.distance')}
                                        className={`${offer && offer.distance ? 'fullinput' : ''}`}
                                    ></Input>
                                    <FormText color="muted">
                                        {t('create_offer.offer_distance_expl')}
                                    </FormText>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="labelWithText" htmlFor="keywords">{`${t('create_offer.offer_keywords')} (${t('global.french')})`}</Label>
                                    <Input type="text" name="keywords" value={offer.keywords} onChange={e => handleUpdateAdInput(e)} placeholder={t('create_offer_placeholders.keywords')}
                                        className={`${offer && offer.keywords ? 'fullinput' : ''}`}
                                    ></Input>
                                </Col>
                                <Col>
                                    <Label className="labelWithText" htmlFor="englishKeywords">{`${t('create_offer.offer_keywords')} (${t('global.english')})`}</Label>
                                    <Input type="text" name="englishKeywords" value={offer.englishKeywords} onChange={e => handleUpdateAdInput(e)} placeholder={t('create_offer_placeholders.keywords')}
                                        className={`${offer && offer.englishKeywords ? 'fullinput' : ''}`}
                                    ></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        <div className="registerButtonWrapper" >
                            <Button
                                onClick={e => handleEditAd(e)}
                                className={`${isLoadingPage ? 'loading' : ''} text-uppercase mt-4 kwanukobutton`}
                            >
                                {
                                    isLoadingPage ?
                                        <Loading button={true} />
                                        :
                                        t('create_offer.submit2')
                                }
                            </Button>
                        </div>
                    </Form>
                </div>
            </div >

        )
    }

    return null;
}

export default OfferEditComponent;