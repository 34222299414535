import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import AppConfig from '../../config/config';
import { connect } from "react-redux";
import {  } from '../../actions/offerAction';

export class MapContainer extends Component {

  constructor(props) {

    super(props);
    this.idleCalled = false;
    this.map = React.createRef();
    this.circle = React.createRef();
  }

  handleMapIdle = () => {
    if (!this.idleCalled) {
      const bounds = this.circle.current.circle.getBounds();
      this.map.current.map.fitBounds(bounds);
      this.idleCalled = true;
    }
  }

  render() {

    const { Layout, displayAddress } = this.props;

    return (
      <Layout
        offer={this.props.offer}
        google={this.props.google}
        map={this.map}
        circle={this.circle}
        handleMapIdle={this.handleMapIdle.bind(this)}
        displayAddress={displayAddress}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  offer: state.offer.offer,
});

const mapDispatchToProps = {
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: AppConfig.googlePlaceAPIkey
})(MapContainer);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedContainer);;