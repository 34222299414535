import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MainContainer from '../containers/Layout/Main'

import HomeContainer from '../containers/Pages/Home';
import SignUpContainer from '../containers/Pages/SignUp';
import LoginContainer from '../containers/Pages/Login';
import OfferFormContainer from '../containers/Pages/OfferForm';
import OfferSingleContainer from '../containers/Pages/OfferSingle';
import AdEditContainer from '../containers/Pages/AdEdit';
import ResetPasswordContainer from '../containers/Pages/ResetPassword';
import UserPageContainer from '../containers/Pages/UserPage';
import LandingPageContainer from '../containers/Pages/LandingPage';
import PrivacyContainer from '../containers/Pages/Privacy';
import TermsContainer from '../containers/Pages/Terms';

import HeaderComponent from '../components/Layout/Header';
import FooterComponent from '../components/Layout/Footer';

import HomeComponent from '../components/Pages/Home';
import SignUpComponent from '../components/Pages/SignUp';
import LoginComponent from '../components/Pages/Login';
import OfferFormComponent from '../components/Pages/OfferForm';
import OfferSingleComponent from '../components/Pages/OfferSingle';
import AdEditComponent from '../components/Pages/OfferEdit';
import ResetPasswordComponent from '../components/Pages/PasswordReset';
import UserPageComponent from '../components/Pages/UserPage';
import LandingPageComponent from '../components/Pages/LandingPage';
import PrivacyComponent from '../components/Pages/Privacy';
import TermsComponent from '../components/Pages/Terms';

import { useTranslation } from 'react-i18next';

const Index = (props) => {
    const { t } = useTranslation();

    return (
        <MainContainer header={HeaderComponent} footer={FooterComponent} {...props}>
            <Switch>
                <Route exact path="/" component={(props) => <HomeContainer {...props} Layout={HomeComponent} title={t("global.home")} />} />
                <Route exact path="/auth/facebook" component={(props) => <HomeContainer  {...props} Layout={HomeComponent} title={t("global.home")} />} />
                <Route exact path="/signup" component={(props) => <SignUpContainer {...props} Layout={SignUpComponent} title={t("global.register")} />} />
                <Route exact path="/login" component={(props) => <LoginContainer {...props} Layout={LoginComponent} title={t("global.login_tab")} />} />
                <Route exact path="/passwordreset/:token" component={(props) => <ResetPasswordContainer {...props} Layout={ResetPasswordComponent} title={t("global.reset_tab")} />} />
                <Route exact path="/account" component={(props) => <UserPageContainer {...props} Layout={UserPageComponent} title={t("global.account")} />} />
                <Route exact path="/offercreate" component={(props) => <OfferFormContainer {...props} Layout={OfferFormComponent} title={t('create_offer.title')} />} />
                <Route exact path="/offeredit/:id" component={(props) => <AdEditContainer {...props} Layout={AdEditComponent} title={t('global.edit_offer_tab')} />} />
                <Route exact path="/pages/:id" component={(props) => <OfferSingleContainer {...props} Layout={OfferSingleComponent} title={t('global.offer_tab')} />} />
                <Route exact path="/landing" component={(props) => <LandingPageContainer {...props} Layout={LandingPageComponent} title={t('global.landing_tab')} />} />
                <Route exact path="/terms" component={(props) => <TermsContainer {...props} Layout={TermsComponent} title={t('global.terms')} />} />
                <Route exact path="/privacy" component={(props) => <PrivacyContainer {...props} Layout={PrivacyComponent} title={t('global.privacy')} />} />
                <Redirect to='/' />
            </Switch>
        </MainContainer>
    )
}

export default Index;
