import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from "../../actions/userAction";
import { isConnected } from "../../actions/userAction";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleLogout = e => {
        e.preventDefault();
        this.props.logout().then(() => {
            window.location.reload();
            this.props.history.push("/");
        }).catch(error => { });
    }

    render() {
        const { Layout } = this.props;
        return (
            <Layout
                user={this.props.user}
                logout={this.handleLogout.bind(this)}
                currentPath={this.props.location.pathname}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {
    logout,
    isConnected
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));