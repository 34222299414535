import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Navbar, NavbarBrand } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { BiUserCircle } from 'react-icons/bi';
import { IconContext } from "react-icons";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import '../../styles/Layout/header.scss';

const HeaderComponent = ({ user, logout, currentPath }) => {

    const { t, i18n } = useTranslation();

    const handleClick = lang => {
        i18n.changeLanguage(lang);
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <>
            <Navbar className="headerContainer">

                <NavbarBrand href="/">
                    <img className="logo" src={require("../../assets/logo/kwanuko_logo_horizontal.png")} alt="Kwanuko" />
                    <img className="logomobile" src={require("../../assets/logo/kwanuko_dragon.png")} alt="Kwanuko" />

                </NavbarBrand>

                {!user.token ?
                    <>
                        <div className="headerRight">


                            {(currentPath === '/signup' || currentPath === '/landing') ?
                                <></>
                                :
                                <Link to="/landing" className="animatedLink"><p className="m-0 text-uppercase">{t("global.register_header")}</p></Link>
                            }
                            <Link to="/login">
                                <button className="kwanukobutton text-uppercase">{t("global.login")}</button>
                            </Link>

                            <div className="languageChoice">
                                <button className={i18n.language === 'fr' ? "kwanukobuttonlang montbold " : "kwanukobuttonlang"} onClick={() => handleClick('fr')}>fr</button>
                                <span style={{ marginBottom: '5px' }}>/</span>
                                <button className={i18n.language === 'en' ? "kwanukobuttonlang montbold " : "kwanukobuttonlang"} onClick={() => handleClick('en')}>en</button>
                            </div>
                        </div>

                    </>
                    :
                    <>
                        <div className="headerRight">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle caret>
                                    <span className="dropdown-name">{user.user.firstname} {user.user.lastname}</span>
                                    <IconContext.Provider value={{ color: "#003a24", className: "global-class-name", style: { cursor: "pointer" } }} >
                                        <BiUserCircle size={'2.5em'} />
                                    </IconContext.Provider>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Link to='/account'>
                                        <DropdownItem>
                                            {t("my_account.my_account")}
                                        </DropdownItem>
                                    </Link>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={e => logout(e)}>{t("global.logout")}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            <div className="languageChoice pl-0">
                                <button className={i18n.language === 'fr' ? "kwanukobuttonlang montbold" : "kwanukobuttonlang"} onClick={() => handleClick('fr')}>fr</button>
                                <span style={{ marginBottom: '5px' }}>/</span>
                                <button className={i18n.language === 'en' ? "kwanukobuttonlang montbold" : "kwanukobuttonlang"} onClick={() => handleClick('en')}>en</button>
                            </div>
                        </div>
                    </>
                }
            </Navbar>

        </>
    )
};

export default HeaderComponent;