import axios from 'axios';
import qs from 'querystring';
import AppConfig from '../config/config';

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const REQUEST_USER_DATA = 'REQUEST_USER_DATA';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const RECEIVE_INTERCEPTOR_DATA = 'RECEIVE_INTERCEPTOR_DATA';
export const REGISTER_OK = 'REGISTER_OK';
export const RECEIVE_USER_CARD_DATA = 'RECEIVE_USER_CARD_DATA';
export const RECEIVE_USER_IMAGE_DATA = 'RECEIVE_USER_IMAGE_DATA';
export const RECEIVE_MULTIPLE_USER_IMAGE_DATA = 'RECEIVE_MULTIPLE_USER_IMAGE_DATA';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DATA_SEND = 'DATA_SEND';

export const requestUserData = () => ({
    type: REQUEST_USER_DATA
});

export const receiveUserData = (data) => ({
    type: RECEIVE_USER_DATA,
    payload: data
});

export const receiveInterceptorId = (data) => ({
    type: RECEIVE_INTERCEPTOR_DATA,
    payload: data
});

export const logIn = () => ({
    type: LOG_IN
});

export const logOut = () => ({
    type: LOG_OUT
});

export const registered = () => ({
    type: REGISTER_OK
});

export const receiveCardData = (data) => ({
    type: RECEIVE_USER_CARD_DATA,
    payload: data
});

export const receiveImageData = (data) => ({
    type: RECEIVE_USER_IMAGE_DATA,
    payload: data
});

export const receiveMultipleImageData = (data) => ({
    type: RECEIVE_MULTIPLE_USER_IMAGE_DATA,
    payload: data
});

export const clearImages = () => ({
    type: CLEAR_IMAGES
});

export const deleteImage = (data) => ({
    type: DELETE_IMAGE,
    payload: data
})

export const sendData = () => ({
    type: DATA_SEND
})

export const isConnected = () => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        const token = getState().user.token;
        if (!token) {
            return reject();
        }

        axios.interceptors.request.use((config) => {
            config.headers.Authorization = `JWT ${token}`;
            return config;
        });

        resolve()
    })
);

export const uploadFile = (file) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());
        let body = {};
        body.fileName = Math.random().toString().substr(2, 5) + '-' + Date.now() + '.' + file.name.split('.').pop();

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const token = getState().user.user.token;
            body.file = reader.result;

            axios.post(AppConfig.baseUrlApi + '/amazon/upload', body, {
                headers: { Authorization: `JWT ${token}` }
            }).then(response => {
                dispatch(receiveImageData(response.data.location));
                resolve();
            })
                .catch(err => {
                    dispatch(receiveImageData(undefined));
                    reject(err);
                })
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    })
)

export const uploadMultipleFiles = (files) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());
        const token = getState().user.user.token;

        // eslint-disable-next-line array-callback-return
        files.map((file) => {
            let body = {};
            body.fileName = Math.random().toString().substr(2, 5) + '-' + Date.now() + '.' + file.name.split('.').pop();

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                body.file = reader.result;

                axios.post(AppConfig.baseUrlApi + '/amazon/upload', body, {
                    headers: { Authorization: `JWT ${token}` }
                }).then(response => {
                    dispatch(receiveMultipleImageData(response.data.location));
                    return resolve();
                }).catch(err => {
                    console.log('ERROR -> userAction -> uploadMultipleFiles -> reader.onload -> amazon/post', err);
                    dispatch(receiveMultipleImageData([]));
                    return reject(err);
                })
            }
            reader.onerror = function (error) {
                console.log('Error: onload', error);
            };
        });
    })
)

export const login = (email, password) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestUserData());
        axios
            .post(AppConfig.baseUrlApi + '/user/login', { email, password })
            .then(response => {
                if (!response.data.error) {
                    const interceptorId = axios.interceptors.request.use((config) => {
                        config.headers.Authorization = `JWT ${response.data.token}`;
                        return config;
                    });
                    _dispatch(receiveInterceptorId(interceptorId));
                    _dispatch(receiveUserData({
                        fail: false,
                        message: '',
                        ...response.data.user,
                        token: response.data.token
                    }));
                    return resolve();
                } else {
                    _dispatch(receiveUserData({
                        fail: true,
                        message: response.data,
                    }));
                    reject({ message: response.data });
                }
            }).catch((error) => {
                _dispatch(receiveUserData({
                    fail: true,
                    message: error.response ? error.response.data : {},
                }));
                reject({ message: error.response ? error.response.data : {} });
            })
    })
);

export const loginFacebook = (code) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {

        axios.get(AppConfig.baseUrlApi + '/facebook/callback?code=' + code, {})
            .then(response => {
                if (!response.data.error) {
                    const interceptorId = axios.interceptors.request.use((config) => {
                        config.headers.Authorization = `JWT ${response.data.token}`;
                        return config;
                    });

                    _dispatch(receiveInterceptorId(interceptorId));
                    _dispatch(receiveUserData({
                        fail: false,
                        message: '',
                        ...response.data.user,
                        token: response.data.token
                    }));
                    return resolve();
                } else {
                    _dispatch(receiveUserData({
                        fail: true,
                        message: response.data.message,
                    }));
                    reject({ message: response.data.message });
                }
            }).catch((error) => {
                _dispatch(receiveUserData({
                    fail: true,
                    message: error.response ? error.response.data.message : 'Une erreur inconnu est survenue',
                }));
                reject({ message: error.response ? error.response.data.message : 'Une erreur inconnu est survenue' });
            })
    })
);

export const loginFacebookToken = (access_token) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        axios.get(AppConfig.baseUrlApi + '/auth/facebook/token?access_token=' + access_token)
            .then(response => {
                if (!response.data.error) {
                    const interceptorId = axios.interceptors.request.use((config) => {
                        config.headers.Authorization = `JWT ${response.data.token}`;
                        return config;
                    });

                    _dispatch(receiveInterceptorId(interceptorId));
                    _dispatch(receiveUserData({
                        fail: false,
                        message: '',
                        ...response.data.user,
                        token: response.data.token
                    }));
                    return resolve();
                } else {
                    _dispatch(receiveUserData({
                        fail: true,
                        message: response.data.message,
                    }));
                    reject({ message: response.data.message });
                }
            }).catch((error) => {
                _dispatch(receiveUserData({
                    fail: true,
                    message: error.response ? error.response.data.message : 'Une erreur inconnu est survenue',
                }));
                reject({ message: error.response ? error.response.data.message : 'Une erreur inconnu est survenue' });
            })
    })
);

export const logout = () => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        axios.interceptors.request.eject(getState().user.interceptorId);
        _dispatch(logOut());
        resolve();
    })
);

export const updateUser = (user) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());
        let userToUpdate = { ...user };

        //fields to omit
        delete userToUpdate.token;
        delete userToUpdate.fail;
        delete userToUpdate.message;
        delete userToUpdate.cards;
        delete userToUpdate.currentCard;
        delete userToUpdate.passwordResetToken;
        delete userToUpdate.passwordResetTokenExpiresAt;

        //fields to omit for dispatch
        delete user.fail;
        delete user.message;
        axios.patch(AppConfig.baseUrlApi + '/account/update/profile', userToUpdate)
            .then(response => {
                dispatch(receiveUserData({ token: getState().user.token, ...response.data.user }))
                return resolve();
            })
            .catch(err => {
                dispatch(receiveUserData({}))
                return reject(err);
            })

    })
);

export const getUserData = () => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());
        axios.get(AppConfig.baseUrlApi + '/account/me')
            .then(response => {
                dispatch(receiveUserData({ token: getState().user.token, ...response.data }))
                return resolve();
            })
            .catch(err => {
                dispatch(receiveUserData({}))
                return reject();
            })
    })
);

export const createUser = (user) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestUserData());
        delete user.confirmPassword;

        axios.post(AppConfig.baseUrlApi + '/user/register', user)
            .then(_ => {
                // _dispatch(login(user.email, user.password)).then(resolve).catch(reject);
                resolve();
            }).catch(err => {
                _dispatch(logOut());
                return reject(err.response.data);
            })
    })
);

export const forgotPassword = (email) => (
    (dispatch) => new Promise((resolve, reject) => {
        axios.post(AppConfig.baseUrlApi + '/user/forgot', { email })
            .then(response => {
                if (!response.data.error) {
                    dispatch(sendData());
                    resolve();
                }
            }).catch((err) => {
                dispatch(sendData());
                reject(err);
            })
    })
);

export const resetPassword = (token, password) => (
    (dispatch) => new Promise((resolve, reject) => {
        axios.post(AppConfig.baseUrlApi + '/user/password', { token, password })
            .then(response => {
                if (!response.data.error) {
                    dispatch(sendData());
                    resolve({ response });
                }
            }).catch((err) => {
                dispatch(sendData());
                reject({ message: err });
            })
    })
);
export const sendSponsorCode = (name, email, lastname, firstname, sponsorCode) => (
    (dispatch) => new Promise((resolve, reject) => {
        axios.post(AppConfig.baseUrlApi + '/account/sponsor', { email: email, firstname: name, sponsorFullName: firstname + ' ' + lastname, sponsorCode: sponsorCode })
            .then(response => {
                if (!response.data.error) {
                    dispatch(sendData());
                    resolve({ response });
                }
            }).catch((err) => {
                dispatch(sendData());
                reject({ message: err });
            })
    })
);
export const updatePassword = (password) => (
    (dispatch) => new Promise((resolve, reject) => {
        axios.post(AppConfig.baseUrlApi + '/account/update/password', { password })
            .then(response => {
                if (!response.data.error) {
                    dispatch(sendData());
                    resolve({ response });
                }
            }).catch((err) => {
                dispatch(sendData());
                reject({ message: err });
            })
    })
);

export const createUserCard = (card) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());
        const token = getState().user.user.token;
        const instance = axios.create();
        instance.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${AppConfig.stripePublicKey}`;
            return config;
        });

        instance.post('https://api.stripe.com/v1/tokens', qs.stringify({
            'card[name]': card.name,
            'card[number]': card.number,
            'card[exp_month]': card.expiry.split('/')[0],
            'card[exp_year]': card.expiry.split('/')[1],
            'card[cvc]': card.cvc,
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            let formattedMonth = response.data.card.exp_month;
            if (response.data.card.exp_month.toString().length === 1)
                formattedMonth = "0" + response.data.card.exp_month;
            if (response.data.id) {
                const cardInfo = {
                    stripeToken: response.data.id,
                    billingCardLast4: response.data.card.last4,
                    billingCardBrand: response.data.card.brand,
                    billingCardExpMonth: formattedMonth,
                    billingCardExpYear: response.data.card.exp_year,
                    billingCardFirstname: response.data.card.name,
                    billingCardLastname: ""
                }
                axios.post(AppConfig.baseUrlApi + '/subscription/subscribe', cardInfo, {
                    headers: { Authorization: `JWT ${token}` }
                })
                    .then(response => {
                        if (!response.data.error) {
                            resolve(dispatch(getUserData()));
                        } else {
                            reject();
                        }
                    }).catch((err) => {
                        reject(err);
                    })
            }
        }).catch((err) => {
            reject(err);
        })
    })
);

export const updateUserCard = (card) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());
        const token = getState().user.user.token;
        const instance = axios.create();
        instance.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${AppConfig.stripePublicKey}`;
            return config;
        });

        instance.post('https://api.stripe.com/v1/tokens', qs.stringify({
            'card[name]': card.name,
            'card[number]': card.number,
            'card[exp_month]': card.expiry.split('/')[0],
            'card[exp_year]': card.expiry.split('/')[1],
            'card[cvc]': card.cvc,
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            if (response.data.id) {
                let formattedMonth = response.data.card.exp_month;
                if (response.data.card.exp_month.toString().length === 1)
                    formattedMonth = "0" + response.data.card.exp_month;
                const cardInfo = {
                    stripeToken: response.data.id,
                    billingCardLast4: response.data.card.last4,
                    billingCardBrand: response.data.card.brand,
                    billingCardExpMonth: formattedMonth,
                    billingCardExpYear: response.data.card.exp_year,
                    billingCardFirstname: response.data.card.name,
                    billingCardLastname: ""
                }
                axios.post(AppConfig.baseUrlApi + '/account/update/billing', cardInfo, {
                    headers: { Authorization: `JWT ${token}` }
                })
                    .then(response => {
                        if (!response.data.error) {
                            resolve(dispatch(getUserData()));
                        } else {
                            reject();
                        }
                    }).catch((err) => {
                        reject(err);
                    })
            }
        }).catch((err) => {
            reject(err);
        })
    })
);

export const chooseSubscription = (accept, data) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());

        if (accept) {
            axios.post(AppConfig.baseUrlApi + '/subscription/accept', { paymentType: data.paymentType })
                .then(response => {
                    if (!response.data.error) {
                        resolve(dispatch(getUserData()));
                    } else {
                        reject();
                    }
                }).catch((err) => {
                    reject(err);
                })
        } else {
            axios.post(AppConfig.baseUrlApi + '/subscription/refuse', { "reason": data.refuseMessage })
                .then(response => {
                    if (!response.data.error) {
                        resolve(dispatch(getUserData()));
                    } else {
                        reject();
                    }
                }).catch((err) => {
                    reject(err);
                })
        }
    })
);

export const cancelSubscription = () => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestUserData());

        axios.get(AppConfig.baseUrlApi + '/subscription/cancel')
            .then(response => {
                if (!response.data.error) {
                    resolve(dispatch(getUserData()));
                } else {
                    reject();
                }
            })
            .catch((err) => {
                reject(err);
            });
    })
)
