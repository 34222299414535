const isLocal = window.location.hostname.includes('localhost');
const isDev = window.location.hostname.includes('kwanuko-dev');
const isStaging = window.location.hostname.includes('kwanuko-staging');

export default {
    DEV: isLocal,
    baseUrlApi: (isLocal) ? 'https://kwanuko-dev.herokuapp.com/api' : '/api',
    baseUrl: (isLocal) ? 'https://kwanuko-dev.herokuapp.com/api' : '/api',
    userUriCheckConnected: 'user/token',
    userUriLogin: 'user/login',
    userUriRegister: 'user/register',
    googlePlaceAPIkey: 'AIzaSyDZnzAXnBMZL0lEPifGIhcrhSmNGQVENzc',
    stripePublicKey:
        isDev || isStaging || isLocal ?
            'pk_test_51HP8evFqH1GXgJn3bu42hPEzAlkpjhUYwZXPB70fc4PQJ3yOo0XRdEL7NVs5lc156Ub57o2qkxb05WDCBkREPtRX0034TxFsBp'
            :
            'pk_live_51HP8evFqH1GXgJn3YkDFQWJojDYCDGw4IMk3NzA3ribFM5ClhRuIOAqcd2L1Jm8wur3eVrEL8zlbs1hJGGpbOpXd00hXIRjdV2',
    tinyAPIKey: '2nkcvigg74gezzci2jrxt7xmopfxqvwlarzfygtf1uat708f'
};
