/* global document */
import 'react-app-polyfill/ie9';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ScrollToTop from './containers/Utils/ScrollToTop';
import { PersistGate } from 'redux-persist/es/integration/react';
import { createBrowserHistory } from "history";

import configureStore from './store/indexStore';
import Routes from './routes/index';

import 'bootstrap/dist/css/bootstrap.min.css';

// Load css
import './styles/style.scss';

import './translations/i18n';

const { persistor, store, dispatch } = configureStore();
const history = createBrowserHistory();

// persistor.purge(); // Debug to clear persist

//<PersistGate loading={<Loading />} persistor={persistor}>

const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router dispatch={dispatch} store={store} history={history}>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </Router>
    </PersistGate>
  </Provider>
);

ReactDOM.render(
  <Suspense fallback="">
    <Root />
  </Suspense>
, document.getElementById('root'));
