import React from 'react';
import dragon from '../../assets/logo/kwanuko_dragon.png';

import '../../styles/UI/loading.scss';

const Loading = ({ fullContainer, button }) => {

    return (
        <div className={`loadingContainer ${fullContainer ? 'loadingFullContainer' : ''}`}>
            <img src={dragon} className={button ? 'logo_button' : 'logo'} alt="" />
        </div>
    )
};

export default Loading;