export const generalMessages = {};

export const successMessages = {};

export const errorMessagesLogin = {
    email: {
        empty: "messages.email_empty"
    },
    password: {
        empty: "messages.password_empty"
    },
    unknownCredentials: "messages.unknownCredentials"

};

export const errorMessagesForgotSend = {
    email: {
        empty: "messages.email_empty"
    }
};

export const errorMessagesAd = {
    title: {
        empty: "messages.title_empty",
    },
    englishTitle: {
        empty: "messages.title_english_empty",
    },
    bannerImage: {
        empty: "messages.bannerImage_empty"
    },
    price: {
        empty: "messages.price_empty",
    },
    address: {
        empty: "messages.address_empty",
    },
    atLeastOnedescription: {
        empty: "messages.description_empty",
    },
    category: {
        empty: "messages.category_empty",
    },
    distance: {
        empty: "messages.distance_empty",
    },
    atLeastOnekeywords: {
        empty: "messages.keywords_empty",
    },
    newCategoryIfOther: {
        empty: "messages.new_category"
    }
}
export const errorPasswordFormat = {
    password: {
        format: "messages.password_format"
    },
    confirmPassword: {
        match: "messages.confirm_password",
    }
}
export const errorMessagesRegister = {
    lastname: {
        empty: "messages.lastname_empty"
    },
    firstname: {
        empty: "messages.firstname_empty",
    },
    email: {
        empty: "messages.email_empty",
        isEmail: "messages.email_isEmail"
    },
    phone: {
        empty: "messages.phone_empty",
        isPhone: "messages.phone.isPhone"
    },
    address: {
        empty: "messages.address_empty"
    },
    intro: {
        empty: "messages.intro_empty"
    },
    area: {
        empty: "messages.area_empty"
    },
    password: {
        empty: "messages.password_empty"
    },
    confirmPassword: {
        empty: "messages.passwordConfirm_empty"
    },



};

export const errorMessagesUpdate = {
    bankDetails: {
        empty: "messages.bankDetails_empty"
    },
    street: {
        empty: "messages.street_empty"
    },
    city: {
        empty: "messages.city_empty"
    },
    postalCode: {
        empty: "messages.postalCode_empty",
    },
    tel: {
        empty: "messages.phone_empty",
        numeric: "messages.phone_isPhone"
    },
    email: {
        empty: "messages.email_empty"
    }
}

export const errorPasswordUpdate = {
    password: {
        empty: "messages.password_empty",
        format: "messages.password_format"
    },
    passwordConfirmation: {
        empty: "messages.passwordConfirm_empty"
    },
}

export const errorMessagesForgot = {
    unavailableLink: "messages.unavailableLink",
    unknownEmail: "messages.email_unknown"
}

export const changePhoneNumber = {
    inputPhoneChange: {
        empty: "messages.phone_empty",
        numeric: "messages.phone_isPhone"
    }
}
export const errorMessagesReset = {
    password: {
        empty: "messages.password_empty",
        format: "messages.password_format"
    },
    passwordConfirmation: {
        empty: "messages.passwordConfirm_empty"
    },
}
export const errorMessagesSponsor = {
    sponsorName: {
        empty: "messages.sponsorName_empty"
    },
    sponsorEmail: {
        empty: "messages.sponsorEmail_empty",
        isEmail: "messages.email_isEmail"
    },
}