export default {
    isFetching: false,
    isRequesting: false,
    offer: {},
    offers: [],
    userOffers: [],
    offerCount: undefined,
    currentPage: undefined,
    searchedBy: undefined,
    searchData: [],
    geoData: undefined,
    foundOffers: true
};