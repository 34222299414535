import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isConnected } from "../../actions/userAction";
import Loading from '../../components/UI/Loading';

import Header from './Header';
import Footer from './Footer';

class MainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.props.isConnected()
            .then(() => {
                this.setState({
                    isLoading: false
                })
            })
            .catch(() => {
                this.setState({
                    isLoading: false
                })
            });
    }

    setLanguageToFrench = () => {
        localStorage.setItem('language', 'fr');
        window.location.reload();
    }

    setLanguageToEnglish = () => {
        localStorage.setItem('language', 'en');
        window.location.reload();
    }

    render() {

        const { header, footer, children } = this.props;

        if (this.state.isLoading) {
            return (
                <Loading/>
            )
        }

        return (
            <>
                <Header
                    Layout={header}
                    user={this.props.user}
                    setLanguageToEnglish={this.setLanguageToEnglish.bind(this)}
                    setLanguageToFrench={this.setLanguageToFrench.bind(this)} />
                <div id="mainContainer">
                    {children}
                </div>
                <Footer
                    Layout={footer}
                    user={this.props.user}/>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {
    isConnected
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);