import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import AppConfig from '../../config/config';
import { connect } from "react-redux";

const initialPosition = {
  zoom: 10,
  center: { lat: 45.508888, lng: -73.561668 }
};

class MapList extends Component {

  constructor(props) {

    super(props);

    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      zoom: initialPosition.zoom,
      currentAd: undefined,
      isLoading: true
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      isLoading: false,
      center: this.props.geoData ? this.props.geoData : initialPosition.center
    });
  }

  componentDidUpdate(previousProps) {
    if ((previousProps.offers !== this.props.offers) || (previousProps.geoData !== this.props.geoData)) {
      this.setState({
        ...this.state,
        isLoading: true,
        offers: this.props.offers,
        center: this.props.geoData
      }, () => {
        this.setState({ isLoading: false });
      })
    }
  }

  selectedAd = (offer) => {

    //this variable has to be the same as the Marker props in Component
    const place = {
      title: offer.title,
      englishTitle: offer.englishTitle,
      uniqueTitle: offer.uniqueTitle,
      banner: offer.bannerImage,
      category: offer.category ? offer.category.title : undefined,
      price: offer.price,
      offerId: offer.id,
      position: { lat: offer.latitude, lng: offer.longitude }
    }

    this.setState({
      selectedPlace: place,
      showingInfoWindow: true,
      zoom: 17,
      center: place.position,
      currentAd: place.offerId
    }
    )
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
      zoom: 17,
      center: marker.position,

      currentAd: props.offerId
    });
  };

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
      zoom: initialPosition.zoom,
      center: initialPosition.center,

      currentAd: undefined
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
        zoom: initialPosition.zoom,
        center: initialPosition.center,

        currentAd: undefined
      });
  };

  render() {

    const { Layout } = this.props;

    return (
      <Layout
        initialCenter={this.state.center}
        google={this.props.google}
        center={this.state.center}
        clicked={this.onMapClicked.bind(this)}
        onMarkerClick={this.onMarkerClick.bind(this)}
        zoom={this.state.zoom}
        closed={this.onInfoWindowClose.bind(this)}
        visible={this.state.showingInfoWindow}
        selectedPlace={this.state.selectedPlace}
        offers={this.props.offers}
        selectedAd={this.selectedAd.bind(this)}
        currentAd={this.state.currentAd}
        isLoading={this.state.isLoading}
        foundOffers={this.props.foundOffers}

        searchedBy={this.props.searchedBy}
        searchData={this.props.searchData}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  offers: state.offer.offers,
  foundOffers: state.offer.foundOffers,
  searchedBy: state.offer.searchedBy,
  searchData: state.offer.searchData,
  geoData: state.offer.geoData
});

const mapDispatchToProps = {
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: AppConfig.googlePlaceAPIkey
})(MapList);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedContainer);;