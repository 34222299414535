import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createUser } from "../../actions/userAction";
import { checkFields } from '../../config/utils';
import { errorMessagesRegister, errorPasswordFormat } from '../../config/messages';

import i18n from '../../translations/i18n';

class SignUp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoadingRegister: false,
            isCreated: false,
            formErrors: {},
            user: {},
        };
    }

    componentDidMount = () => {
        if (this.props.location.search.includes('sponsorCode')) {
            this.setState({
                user: {
                    sponsorCode: this.props.location.search.split('=')[1]
                }
            })
        }
    }
    handleUpdateSignUpInput = e => {
        this.setState({ user: { ...this.state.user, [e.target.name]: e.target.value } });
    }

    handlePhoneInput = (phone) => {
        this.setState({ user: { ...this.state.user, phone: '+' + phone } });
    }

    checkPasswordConfirmation = () => {
        this.setState({
            check_confirmation: true,
        })
        if (this.state.user.password && this.state.user.confirmPassword && this.state.user.password !== this.state.user.confirmPassword) {
            this.setState({
                check_confirmation: false
            })
        }
    }

    checkPasswordFormat = () => {
        if (this.state.user.password && this.state.user.password !== '') {
            checkFields(errorPasswordFormat, { password: this.state.user.password }).then(() => {
                this.setState({
                    wrong_format: false
                })
            }).catch(errors => {
                this.setState(state => ({
                    wrong_format: true,
                }))
            })
        }
    }

    handleCreateUser = e => {
        if (e) e.preventDefault();

        this.setState({ isLoadingRegister: true, formErrors: {} });

        checkFields(errorMessagesRegister, this.state.user).then(() => {
            if (this.state.check_confirmation === true && this.state.wrong_format === false) {
                this.props.createUser(this.state.user).then(_ => {
                    this.setState({ isLoadingRegister: false, isCreated: true, formErrors: {} });
                    window.scrollTo(0, 0);
                }).catch(error => {
                    let errors = {
                        existing_user: error.message === "error.register.duplicate" ? i18n.t("register_logic.email_already_exist") : undefined,
                        invalid_sponsor: error.message === "error.register.invalidSponsorCode" ? i18n.t("register_logic.sponsor_code_not_valid") : undefined,
                    };

                    if (Object.values(errors).every(el => el === undefined)) {
                        errors.unknown = i18n.t('register_logic.unknown_error')
                    }

                    this.setState(state => ({
                        formErrors: {
                            ...state.formErrors,
                            register: errors
                        },
                        isLoadingRegister: false,
                    }))
                })
            } else {
                this.setState({
                    isLoadingRegister: false
                })
            }
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    register: errors
                },
                isLoadingRegister: false,
            }))
        })
    }

    render() {
        const { Layout, title } = this.props;

        return (
            <Layout
                user={this.state.user}
                handleUpdateSignUpInput={this.handleUpdateSignUpInput.bind(this)}
                handleCreateUser={this.handleCreateUser.bind(this)}
                created={this.state.isCreated}
                formErrors={this.state.formErrors}
                title={title}
                state={this.state.user}
                isLoadingRegister={this.state.isLoadingRegister}
                checkPasswordConfirmation={this.checkPasswordConfirmation.bind(this)}
                checkPasswordFormat={this.checkPasswordFormat.bind(this)}
                wrong_format={this.state.wrong_format}
                check_confirmation={this.state.check_confirmation}
                handlePhoneInput={this.handlePhoneInput.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    createUser
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);