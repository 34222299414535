import axios from 'axios';
import AppConfig from '../config/config';
import Geocode from 'react-geocode';
import i18n from '../translations/i18n';

export const REQUEST_OFFER_DATA = 'REQUEST_PRODUCT_DATA';
export const RECEIVE_OFFER_DATA = 'RECEIVE_PRODUCT_DATA';
export const RECEIVE_MULTIPLE_OFFER_DATA = "RECEIVE_MULTIPLE_OFFER_DATA";
export const RECEIVE_USER_OFFER_DATA = "RECEIVE_USOFFER_OFFER_DATA";
export const RECEIVE_COUNT_DATA = "RECEIVE_COUNT_DATA";
export const RECEIVE_PAGINATION_DATA = "RECEIVE_PAGINATION_DATA";
export const RECEIVE_SEARCH_DATA = "RECEIVE_SEARCH_DATA";
export const RECEIVE_GEO_DATA = "RECEIVE_SEARC_GEO";
export const RECEIVE_SEARCHED_BY_DATA = "RECEIVE_SEARCHED_BY_DATA";
export const DID_RECEIVE_DATA = "DID_RECEIVE_DATA";
export const DATA_SEND = 'DATA_SEND';

Geocode.setApiKey(AppConfig.googlePlaceAPIkey);

export const requestOfferData = () => ({
    type: REQUEST_OFFER_DATA,
});

export const receiveOfferData = (data) => ({
    type: RECEIVE_OFFER_DATA,
    payload: data,
});

export const receiveMultipleOfferData = (data) => ({
    type: RECEIVE_MULTIPLE_OFFER_DATA,
    payload: data,
});

export const receiveUserOfferData = (data) => ({
    type: RECEIVE_USER_OFFER_DATA,
    payload: data,
});

export const receiveCountData = (data) => ({
    type: RECEIVE_COUNT_DATA,
    payload: data
})

export const receivePaginationData = (data) => ({
    type: RECEIVE_PAGINATION_DATA,
    payload: data
})

export const receiveSearchData = (data) => ({
    type: RECEIVE_SEARCH_DATA,
    payload: data
})

export const receiveGeoData = (data) => ({
    type: RECEIVE_GEO_DATA,
    payload: data
})

export const receiveSearchedByData = (data) => ({
    type: RECEIVE_SEARCHED_BY_DATA,
    payload: data
})

export const didReceiveData = (bool) => ({
    type: DID_RECEIVE_DATA,
    payload: bool
})

export const sendData = () => ({
    type: DATA_SEND,
})

export const createOffer = (offer) => (
    (dispatch) => new Promise((resolve, reject) => {

        if (offer && offer.category && offer.category === "other") {
            offer = { ...offer, category: null }
        }

        axios.post(AppConfig.baseUrlApi + '/offer', offer)
            .then(res => {
                dispatch(sendData());
                resolve({ res });
            })
            .catch((err) => {
                dispatch(sendData());
                reject({ err })
            })
    })
);

export const updateOffer = (id, offer) => (
    (dispatch) => new Promise((resolve, reject) => {
        dispatch(requestOfferData());

        let offerToUpdate = { ...offer };

        if (offerToUpdate && offerToUpdate.category && offerToUpdate.category === "other") {
            offerToUpdate = { ...offer, category: null }
        }

        axios.put(AppConfig.baseUrlApi + '/offer/' + id, offerToUpdate)
            .then(response => {
                dispatch(receiveOfferData({ ...response.data }));
                resolve();
            })
            .catch(err => {
                reject(err);
            })
    })
);

export const getOffer = (id) => (
    (distpatch) => new Promise((resolve, reject) => {
        distpatch(requestOfferData());

        axios.get(AppConfig.baseUrlApi + '/offer/' + id)
            .then(res => {
                return resolve(distpatch(receiveOfferData(res.data)));
            })
            .catch(err => {
                distpatch(receiveOfferData([]));
                reject(err);
            });
    })
);

export const getMultipleOffers = () => (
    (dispatch) => new Promise((resolve, reject) => {
        dispatch(requestOfferData());
        dispatch(receiveSearchedByData("home"));
        dispatch(receiveSearchData([]));
        const criteria = {
            criteria: {
                limit: 100
            }
        }

        axios.post(AppConfig.baseUrlApi + '/offer/count', criteria)
            .then(res => {
                dispatch(receiveCountData(res.data.count));
                dispatch(receivePaginationData(0));
            })
            .catch(err => console.log(err));

        axios.get(AppConfig.baseUrlApi + '/offer?where={"status":"approved"}&sort=createdAt DESC&limit=10')
            .then(res => {
                dispatch(receiveMultipleOfferData(res.data));
                //dispatch(didReceiveData(true));
                resolve();
            })
            .catch(err => {
                dispatch(receiveMultipleOfferData([]));
                reject(err);
            })
    })
);

export const getFilteredOffersByCategory = (category) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestOfferData());
        dispatch(receiveSearchedByData("Category"));
        dispatch(receiveSearchData(["category", category.title, category.englishTitle]));
        const criteria = {
            criteria: { "where": { "category": category.id } }
        }
        axios.post(AppConfig.baseUrlApi + '/offer/count', criteria)
            .then(res => {
                dispatch(receiveCountData(res.data.count));
                dispatch(receivePaginationData(0));
            })
            .catch(err => console.log(err));

        axios.get(AppConfig.baseUrlApi + '/offer?where={"status":"approved","category":' + category.id + '}&sort=createdAt DESC&limit=10')
            .then(res => {
                if (res.data.length === 0) {
                    dispatch(didReceiveData(false));
                    dispatch(getMultipleOffers());
                }
                else dispatch(didReceiveData(true));

                dispatch(receiveMultipleOfferData(res.data));
                resolve();
            })
            .catch(err => {
                dispatch(receiveMultipleOfferData([]));
                reject(err);
            })
    })
);

export const getFilteredOffersBySearch = (keywords, postal) => (
    (dispatch) => new Promise((resolve, reject) => {
        const lang = i18n.language;

        if (!keywords && !postal) {
            dispatch(getMultipleOffers());
            dispatch(receiveSearchedByData("home"));
            resolve();
            return;
        }

        dispatch(requestOfferData());
        dispatch(receiveSearchedByData("Keywords/Postal"));
        dispatch(receiveSearchData(["search", keywords, postal]));


        let query = '/offer?where=';
        let criteria = { where: {} };

        let filteredArray;
        if (keywords) {
            lang === 'fr' ? 
                criteria = { where: { keywords: [] } } 
                : 
                criteria = { where: { englishKeywords: [] } }
            
            const keywordArray = keywords.replaceAll(/\s\s+/g, ",").split(",");
            filteredArray = keywordArray.filter(e => e !== "")

            filteredArray.forEach(word => {
                if (lang === 'fr') {
                    query = query.concat('{"status":"approved","keywords":{"contains":"' + word + '"}}&');
                    criteria.where.keywords.push(word);
                } else {
                    query = query.concat('{"status":"approved","englishKeywords":{"contains":"' + word + '"}}&');
                    criteria.where.englishKeywords.push(word);
                }
            });
        }

        let offersNearPostal = [];
        if (postal) {
            const upperCasePostal = postal.toUpperCase();
            const postalLength = postal.replace(/[^A-Z0-9]/g, "").length;

            const containsOnlyNumbers = /^[0-9]*$/.test(postal.replace(/[^A-Z0-9]/g, ""));
            let noSpacePostal, finalPostal;

            if (containsOnlyNumbers && (postalLength === 5 || postalLength === 9)) {
                // United States Postal Code
                finalPostal = upperCasePostal.replace(/[^0-9]/g, "");
            } else if (postalLength === 6) {
                // Canadian Postal Code
                noSpacePostal = upperCasePostal.replace(/[^A-Z0-9]/g, "");
                finalPostal = noSpacePostal.substring(0, 3) + " " + noSpacePostal.substring(3);
            } else {
                // Invalid Postal Code
                dispatch(didReceiveData(false));
                dispatch(getMultipleOffers());
            }

            Geocode.fromAddress(finalPostal).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    dispatch(receiveGeoData({ lat, lng }));

                    axios.get(AppConfig.baseUrlApi + `/offer/geo?longitude=${lng}&latitude=${lat}&distance=10&sort=createdAt DESC`)
                        .then(res => {
                            offersNearPostal = res.data;

                            function checkIfContainsKeywords(offer) {
                                let result = false
                                filteredArray.forEach(word => {
                                    if (lang === 'fr') {
                                        if (offer.keywords.includes(word)) result = true
                                    } else {
                                        if (offer.englishKeywords.includes(word)) result = true
                                    }
                                });
                                return result;
                            }

                            if (keywords) {
                                offersNearPostal = offersNearPostal.filter(checkIfContainsKeywords);
                            }

                            dispatch(receiveCountData(offersNearPostal.length));
                            dispatch(receivePaginationData(0));
                            if (offersNearPostal.length === 0) {
                                dispatch(didReceiveData(false));
                                dispatch(getMultipleOffers());
                            }
                            else dispatch(didReceiveData(true));

                            dispatch(receiveMultipleOfferData(offersNearPostal.slice(0, 11)))
                            resolve();
                        })
                        .catch(err => console.log(err))
                },
                error => {
                    console.error(error);
                }
            )
        }

        if (keywords && !postal) {
            //to remove the last &
            query = query.substring(0, query.length - 1);

            axios.post(AppConfig.baseUrlApi + '/offer/count', { criteria: criteria })
                .then(res => {
                    dispatch(receiveCountData(res.data.count));
                    dispatch(receivePaginationData(0));
                })
                .catch(err => console.log(err));

            axios.get(AppConfig.baseUrlApi + query + '&sort=createdAt DESC&limit=10')
                .then(res => {
                    if (res.data.length === 0) {
                        dispatch(didReceiveData(false));
                        dispatch(getMultipleOffers());
                    }
                    else dispatch(didReceiveData(true));

                    dispatch(receiveMultipleOfferData(res.data))
                    resolve();
                })
                .catch(err => {
                    dispatch(receiveMultipleOfferData([]));
                    reject(err);
                })
        }
    })
);

export const paginationHomeSearch = (offset) => (
    (dispatch) => new Promise((resolve, reject) => {
        dispatch(requestOfferData());

        axios.get(AppConfig.baseUrlApi + '/offer?sort=createdAt DESC&skip=' + offset + '&limit=10')
            .then(res => {
                return resolve(dispatch(receiveMultipleOfferData(res.data)));
            })
            .catch(err => {
                dispatch(receiveMultipleOfferData([]));
                reject(err);
            })
    })
);

export const paginationFilterSearch = (offset) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(requestOfferData());

        let categoryId = getState().offer.offers[0].category.id;
        axios.get(AppConfig.baseUrlApi + '/offer?where={"status":"approved","category":' + categoryId + '}&sort=createdAt DESC&skip=' + offset + '&limit=10')
            .then(res => {
                dispatch(receiveMultipleOfferData(res.data));
                resolve();
            })
            .catch(err => {
                dispatch(receiveMultipleOfferData([]));
                reject(err);
            })
    })
);

export const paginationWordSearch = (offset) => (
    (dispatch, getState) => new Promise((resolve, reject) => {
        const lang = i18n.language;

        dispatch(requestOfferData());

        let keywords = getState().offer.searchData[1];
        let postal = getState().offer.searchData[2];

        if (!keywords && !postal) return;

        let query = '/offer?where=';

        let filteredArray;
        if (keywords) {
            const keywordArray = keywords.replaceAll(/\s\s+/g, ",").split(",");
            var filteredArrray = keywordArray.filter(e => e !== "")

            filteredArrray.forEach(word => {
                lang === 'fr' ?
                    query = query.concat('{"status":"approved","keywords":{"contains":"' + word + '"}}&')
                    :
                    query = query.concat('{"status":"approved","englishKeywords":{"contains":"' + word + '"}}&')
            });
        }

        let offersNearPostal = [];
        if (postal) {
            const upperCasePostal = postal.toUpperCase();
            const postalLength = postal.replace(/[^A-Z0-9]/g, "").length;

            const containsOnlyNumbers = /^[0-9]*$/.test(postal.replace(/[^A-Z0-9]/g, ""));
            let noSpacePostal, finalPostal;

            if (containsOnlyNumbers && (postalLength === 5 || postalLength === 9)) {
                // United States Postal Code
                finalPostal = upperCasePostal.replace(/[^0-9]/g, "");
            } else if (postalLength === 6) {
                // Canadian Postal Code
                noSpacePostal = upperCasePostal.replace(/[^A-Z0-9]/g, "");
                finalPostal = noSpacePostal.substring(0, 3) + " " + noSpacePostal.substring(3);
            } else {
                // Invalid Postal Code
                return reject();
            }

            Geocode.fromAddress(finalPostal).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;

                    axios.get(AppConfig.baseUrlApi + `/offer/geo?longitude=${lng}&latitude=${lat}&distance=10&sort=createdAt DESC`)
                        .then(res => {
                            offersNearPostal = res.data;
                            
                            function checkIfContainsKeywords(offer) {
                                let result = false
                                filteredArray.forEach(word => {
                                    if (lang === 'fr') {
                                        if (offer.keywords.includes(word)) result = true
                                    } else {
                                        if (offer.englishKeywords.includes(word)) result = true
                                    }
                                });
                                return result;
                            }

                            if (keywords) {
                                offersNearPostal = offersNearPostal.filter(checkIfContainsKeywords);
                            }

                            dispatch(receiveMultipleOfferData(offersNearPostal.slice(offset, offset + 11)))
                            resolve();
                        })
                        .catch(err => console.log(err))
                },
                error => {
                    console.error(error);
                }
            )
        }

        if (keywords && !postal) {
            //to remove the last &
            query = query.substring(0, query.length - 1);

            axios.get(AppConfig.baseUrlApi + query + '}&sort=createdAt DESC&skip=' + offset + '&limit=10')
                .then(res => {
                    return resolve(dispatch(receiveMultipleOfferData(res.data)));
                })
                .catch(err => {
                    dispatch(receiveMultipleOfferData([]));
                    reject(err);
                })
        }
    })
);

export const getUserOffers = (user) => (
    (dispatch) => new Promise((resolve, reject) => {
        dispatch(requestOfferData());

        axios.get(AppConfig.baseUrlApi + '/offer?where={"user":' + user.id + "}&sort=createdAt DESC")
            .then(res => {
                return resolve(dispatch(receiveUserOfferData(res.data)));
            })
            .catch(err => {
                dispatch(receiveUserOfferData([]));
                reject(err);
            })
    })
);

export const removeOffer = (id) => (
    (distpatch) => new Promise((resolve, reject) => {
        distpatch(requestOfferData());

        axios.delete(AppConfig.baseUrlApi + '/offer/' + id)
            .then(res => {
                if (!res.data.error) {
                    distpatch(getOffer());
                    resolve();
                }
            })
            .catch(err => reject(err))
    })
);

export const contactForm = (form) => (
    dispatch => new Promise((resolve, reject) => {
        dispatch(requestOfferData());

        axios.post(AppConfig.baseUrlApi + '/offer/contact', form)
            .then(res => {
                dispatch(sendData());
                resolve({ res });
            })
            .catch((err) => {
                dispatch(sendData());
                reject({ err })
            })
    })
);