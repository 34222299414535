import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllCategories } from '../../actions/categoryAction';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { Layout, title } = this.props;

        return (
            <Layout
                title={title}
            />
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    getAllCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);