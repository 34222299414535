import offerReducer from './offerReducer';
import userReducer from './userReducer';
import categoryReducer from './categoryReducer'

const rehydrateReducer = (state = false, action) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            return true;
        default:
            return state;
    }
};

export default {
    rehydrate: rehydrateReducer,
    user: userReducer,
    offer: offerReducer,
    category: categoryReducer,
};
