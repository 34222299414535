import React, { Component } from 'react';
import { connect } from 'react-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { updateOffer, getOffer } from "../../actions/offerAction";
import { getAllCategories } from "../../actions/categoryAction"
import { isConnected, uploadFile, uploadMultipleFiles, deleteImage } from "../../actions/userAction"

import { checkAdFields } from '../../config/utils';
import { errorMessagesAd } from '../../config/messages';
import i18n from '../../translations/i18n';

class AdEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoadingPage: true,
            isLoadingUpload: false,
            isEdited: false,
            formErrors: {},
        };
    }

    componentDidMount = () => {
        this.props.isConnected().then(() => {
            this.props.getAllCategories()
                .then(() => {
                    const offerId = this.props.match && this.props.match.params ? this.props.match.params.id : this.props.route && this.props.route.params && this.props.route.params.id ? this.props.route.params.id : undefined;
                    if (offerId) {
                        this.props.getOffer(offerId)
                            .then(res => {
                                if (this.props.offer.user === this.props.user.user.id) {
                                    this.setState({
                                        isLoadingPage: false,
                                        offer: {
                                            ...this.props.offer,
                                            user: undefined,
                                            status: "modified_waiting_approval",
                                            category: this.props.offer.category ? this.props.offer.category : "other",
                                        },
                                    });
                                } else {
                                    this.props.history.push("/account");
                                }
                            })
                            .catch(err => {
                                this.props.history.push("/account");
                            })
                    }
                })
                .catch(() => {
                    this.props.history.push("/account");
                });
        })
            .catch(() => {
                this.props.history.push("/login");
            })
    }


    componentDidUpdate(previousProps) {
        if (previousProps.otherImages !== this.props.otherImages) {
            this.setState({
                ...this.state,
                isLoading: true,
                offer: {
                    ...this.state.offer,
                    otherImages: this.props.otherImages
                }
            }, () => {
                this.setState({ isLoading: false });
            })
        }
    }

    handleUpdateAdInput = e => {
        if (e.target.name === "keywords") {
            this.setState({ offer: { ...this.state.offer, [e.target.name]: e.target.value.toLowerCase() } });
        } else
            if (e.target.name === "address") {
                geocodeByAddress(e.target.value)
                    .then(results => {
                        getLatLng(results[0]).then(latLng => {
                            this.setState({
                                offer: {
                                    ...this.state.offer,
                                    latitude: latLng.lat,
                                    longitude: latLng.lng,
                                    [e.target.name]: e.target.value
                                }
                            });
                        })
                            .catch(err => { })
                    })
                    .catch(error => { });
            } else
                if (e.target.name === "displayAddress") {
                    this.setState({ offer: { ...this.state.offer, [e.target.name]: e.target.checked } });
                }
                else this.setState({ offer: { ...this.state.offer, [e.target.name]: e.target.value } });
    }

    handleEditorChange = (e, name) => {
        let text = e.target.getContent();

        text = text.replace(/<strong style="font-family: MontBold;"/g, "<strong").replace(/<strong/g, "<strong style='font-family:MontBold;'");
        this.setState({ offer: { ...this.state.offer, [name]: text } });
    }

    uploadBannerImage = image => {
        let aFile = image[0];
        this.setState({ ...this.state, isLoadingUpload: true });

        this.props.uploadFile(aFile).then(() => {
            this.setState({
                ...this.state,
                isLoadingUpload: false,
                offer: {
                    ...this.state.offer,
                    bannerImage: this.props.bannerImage
                }
            });
        }
        )
    }

    uploadOtherImages = (images) => {
        this.setState({ ...this.state, isLoadingUploadOther: true });

        if (this.state.offer.otherImages.length === 2) {
            this.props.deleteImage(this.state.offer.otherImages[1]);

        }

        this.props.uploadMultipleFiles(images).then(() => {
            this.setState({
                ...this.state,
                isLoadingUploadOther: false,
                offer: {
                    ...this.state.offer,
                    otherImages: this.props.otherImages
                }
            });
        }
        )
    }

    onDrop = acceptedFile => {
        return this.uploadBannerImage(acceptedFile)
    };

    onDropMultiple = acceptedFiles => {
        return this.uploadOtherImages(acceptedFiles);
    }

    deleteBannerImage = () => {
        this.setState({
            ...this.state,
            offer: {
                ...this.state.offer,
                bannerImage: undefined
            }
        })
    }

    deleteImage = (url) => {
        this.setState({
            ...this.state, isLoadingUploadDelete: true
        });

        this.props.deleteImage(url);
        this.setState({
            ...this.state,
            isLoadingUploadDelete: false,
            offer: {
                ...this.state.offer,
                otherImages: this.props.otherImages
            }
        })

    }

    generateUniqueTitle = e => {
        let title = e.target.value;

        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        title = title.toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text

        this.setState({
            offer: { ...this.state.offer, uniqueTitle: title }
        });
    }

    handleEditAd = e => {
        if (e) e.preventDefault();

        this.setState({
            ...this.state,
            isLoadingPage: true,
            formErrors: {}
        });

        let atLeastOnedescription = "";
        let atLeastOnekeywords = "";
        let newCategoryIfOther = "not empty";

        if (this.state.offer.description || this.state.offer.englishDescription) atLeastOnedescription = "true";
        if (this.state.offer.keywords || this.state.offer.englishKeywords) atLeastOnekeywords = "true";
        if (this.state.offer.category === 'other' && !this.state.offer.newCategory) newCategoryIfOther = "";

        const data = {
            ...this.state.offer,
            atLeastOnedescription,
            atLeastOnekeywords,
            newCategoryIfOther
        };

        checkAdFields(errorMessagesAd, data).then(() => {
            this.props.updateOffer(this.props.offer.id, this.state.offer).then(_ => {
                this.setState({ isLoadingPage: false, isEdited: true, formErrors: {} });
                this.props.history.push("/account");
            }).catch(error => {
                let errors = {
                    unique_name: error.response.data.message.code === "E_UNIQUE" ? i18n.t('create_offer.unique_name_not_available') : undefined
                    //ADD ERROR CODES HERE
                };

                if (Object.values(errors).every(el => el === undefined)) {
                    errors.unknown = "Erreur inconnue. Veuillez ressayer plus tard.."
                };

                this.setState(state => ({
                    formErrors: {
                        ...state.formErrors,
                        register: errors
                    },
                    isLoadingPage: false,
                }))
            })
        }).catch(errors => {
            this.setState(state => ({
                formErrors: {
                    ...state.formErrors,
                    register: errors
                },
                isLoadingPage: false
            }))
        })
    }

    render() {
        const { Layout, title } = this.props;
        return (
            <Layout
                isLoadingPage={this.state.isLoadingPage}
                isLoadingUpload={this.state.isLoadingUpload}
                isLoadingUploadOther={this.state.isLoadingUploadOther}
                offer={this.state.offer}
                handleUpdateAdInput={this.handleUpdateAdInput.bind(this)}
                handleEditorChange={this.handleEditorChange.bind(this)}
                handleEditAd={this.handleEditAd.bind(this)}
                categories={this.props.categories}
                formErrors={this.state.formErrors}
                onDrop={this.onDrop.bind(this)}
                onDropMultiple={this.onDropMultiple.bind(this)}
                deleteBanner={this.deleteBannerImage.bind(this)}
                deleteImage={this.deleteImage.bind(this)}
                state={this.state}
                title={title}
                generateUniqueTitle={this.generateUniqueTitle.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    offer: state.offer.offer,
    categories: state.category.categories,
    bannerImage: state.user.bannerImage,
    otherImages: state.user.otherImages,
    user: state.user
})

const mapDispatchToProps = {
    updateOffer,
    getOffer,
    getAllCategories,
    isConnected,
    uploadFile,
    uploadMultipleFiles,
    deleteImage
};

export default connect(mapStateToProps, mapDispatchToProps)(AdEdit);